import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { setActiveGymStudioModal } from '../../../ducks/gymStudioActions';

const DropdownWrapper = styled('div')`
  position: relative;
  display: inline-block;
`;

const DropdownOverlay = styled('div')`
  position: fixed;
  top: ${({ headerHeight }) => headerHeight}px;
  left: ${({ sidebarWidth }) => sidebarWidth}px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const DropdownMenu = styled('div')`
  position: absolute;
  text-align: center;
  top: 25px;
  right: 0;
  width: 210px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  z-index: 999;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  box-shadow: 
    0px 1px 3px rgba(99, 95, 111, 0.2),
    0px 4px 16px rgba(99, 95, 111, 0.24);
  cursor: pointer;
    
  &:hover {
    background-color: #FEEBEB;
  }
`;

const DropdownItem = styled('div')`
  color: #911444;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Articulat CF', sans-serif;

  &:hover {
    color: #7b0000;
  }
`;

const SettingsDropdown = ({
  isVisible, closeDropdown, sidebarWidth, headerHeight,
}) => {
  const dispatch = useDispatch();

  const openDeleteConnectionModal = () => {
    dispatch(setActiveGymStudioModal('delete-connection'));
    closeDropdown();
  };

  return (
    <>
      {isVisible && (
      <DropdownOverlay
        onClick={closeDropdown}
        sidebarWidth={sidebarWidth}
        headerHeight={headerHeight}
      />
      )}
      <DropdownWrapper>
        <DropdownMenu isVisible={isVisible}>
          <DropdownItem onClick={openDeleteConnectionModal}>
            Disconnect GymStudio Account
          </DropdownItem>
        </DropdownMenu>
      </DropdownWrapper>
    </>
  );
};

SettingsDropdown.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
  headerHeight: PropTypes.number.isRequired,
};

export default SettingsDropdown;
