import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';

import TooltipCard from './TooltipCard';
import Icon from '../../../../../shared/_TBComponents/Icon';
import Button from '../../../../../shared/components/Button/Button';

const CardContainer = styled('div')`
  background: ${({ bgColor }) => bgColor || '#ffffff'};
  border-radius: 8px;
  padding: 24px;
  box-shadow: 
    0px 1px 1px rgba(8, 5, 10, 0.08),
    0px 1px 4px rgba(8, 5, 10, 0.10); 
  display: flex;
  align-items: center;
  position: relative;
  border: ${({ bgColor }) => (bgColor && bgColor !== '#ffffff' ? '1px solid #0AA36E' : 'none')};
  flex-grow: 1;
  flex-basis: calc(32% - 16px);
  min-width: 250px;
`;

const IconWrapper = styled('div')`
  min-width: 56px;
  min-height: 56px;
  background-color: ${({ iconBgColor }) => iconBgColor || '#E5E5E5'};
  border: ${({ bgColor }) => (bgColor && bgColor !== '#ffffff' ? 'none' : '4px solid rgba(255, 255, 255, 0.6)')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
`;

const StatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.0;
`;

const DataNumber = styled('div')`
  font-size: 36px;
  font-weight: 900;
  color: #333333;
  margin-bottom: 4px;
  font-family: 'Nunito Sans', sans-serif;
`;

const DataText = styled('p')`
  font-size: 12px;
  font-weight: 700;
  color: #616161;
  margin-bottom: 2px;
  font-family: 'Nunito Sans', sans-serif;
`;

const InfoButtonWrapper = styled('div')`
  position: absolute;
  top: 8px;
  right: 8px;
  transform: scale(1.3);

  & button svg path {
    fill: ${({ bgColor }) => (bgColor && bgColor !== '#ffffff' ? '#0AA36E' : '#979797')} !important;
  }
`;

const ShimmerContent = styled('div')`
  width: ${(props) => props.width || '200px'};
  height: 34px;
  margin-left: 8px;
  border-radius: 8px;
`;

const UserStatsCard = ({
  bgColor,
  icon,
  iconSize,
  iconBgColor,
  iconColor,
  borderColor,
  number,
  text,
  isLoading,
  isVisible,
  toggleTooltip,
  tooltipData,
  sidebarWidth,
  headerHeight,
}) => (
  <CardContainer bgColor={bgColor}>
    <IconWrapper iconBgColor={iconBgColor} borderColor={borderColor} bgColor={bgColor}>
      <Icon icon={icon} size={iconSize} color={iconColor} />
    </IconWrapper>
    {isLoading
      ? (
        <Shimmer>
          <ShimmerContent />
        </Shimmer>
      )
      : (
        <>
          <StatsContainer>
            <DataNumber>{number}</DataNumber>
            <DataText>{text}</DataText>
          </StatsContainer>
          <InfoButtonWrapper
            bgColor={bgColor}
          >
            <Button
              icon='info2'
              iconOnly
              customColor='transparent'
              primary
              onClick={toggleTooltip}
            />
          </InfoButtonWrapper>
          {isVisible && (
            <TooltipCard
              description={tooltipData.description || 'No Description'}
              users={tooltipData.users || []}
              isVisible={isVisible}
              closeTooltip={toggleTooltip}
              sidebarWidth={sidebarWidth}
              headerHeight={headerHeight}
              cardData={{
                icon,
                iconBgColor,
                bgColor,
                number,
                text,
                iconColor: '#FFFFFF',
              }}
            />
          )}
        </>
      )}
  </CardContainer>
);

UserStatsCard.propTypes = {
  bgColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  iconBgColor: PropTypes.string,
  borderColor: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  toggleTooltip: PropTypes.func.isRequired,
  tooltipData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        athleteName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  sidebarWidth: PropTypes.number.isRequired,
  headerHeight: PropTypes.number.isRequired,
};

UserStatsCard.defaultProps = {
  bgColor: '#ffffff',
  iconColor: '#FFFFFF',
  iconSize: 24,
  iconBgColor: '#E5E5E5',
  borderColor: 'rgba(255, 255, 255, 0.6)',
  isLoading: false,
};

export default UserStatsCard;
