import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import GymStudioButton from '../../../../../shared/_TBComponents/Button';

const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-height: 645px;
  max-width: 520px;
`;

const Title = styled('h2')`
  font-size: 24px;
  font-weight: 900;
  color: #444444;
  margin-bottom: 16px;
  font-family: 'Nunito Sans', sans-serif;
`;

const Subtitle = styled('p')`
  font-size: 20px;
  color: #444444;
  margin-bottom: 24px;
  font-family: 'Nunito Sans', sans-serif;
`;

const VideoContainer = styled('div')`
  width: 420px;
  height: 285px;
  border-radius: 12px;
  overflow: hidden;
`;

const Description = styled('p')`
  font-size: 14px;
  font-weight: 600;
  color: #444444;
  margin: 32px 0;
  font-family: 'Nunito Sans', sans-serif;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  button {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;

    & svg path {
      fill: #FFFFFF !important;
    }
  }
`;

const TeamBuildrModal = ({ onRequestClose }) => {
  const theme = useTheme();
  return (
    <ModalContent>
      <Title>Download TeamBuildr athlete accounts</Title>
      <Subtitle>
        <strong>Do not edit</strong>
        {' '}
        the file after exporting.
      </Subtitle>

      <VideoContainer>
        <iframe
          src='https://www.youtube.com/embed/GNNiDyoykOE'
          title='Exporting Athlete Information Video'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      </VideoContainer>

      <Description>
        Clicking on &quot;Manage Users&quot; will open a new tab
        where you can select and download the specific data you need.
      </Description>

      <ButtonContainer>
        <GymStudioButton
          name='Go to Manage Users'
          borderColor={theme.colors.gymStudio}
          bgColor={theme.colors.gymStudio}
          txtColor='#FFFFFF'
          fontSize='14px'
          styledTextProps={{
            fontWeight: '700', letterSpacing: '0',
          }}
          icon='gs-arrow-right'
          iconSize={12}
          customBorderRadius='4px'
          onClick={() => window.open(
            `${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app.' : 'app.'}teambuildr.com`}/manage_users`,
            '_blank',
          )}
        />
        <GymStudioButton
          name='Return to GymStudio Hub'
          bgColor='#FFFFFF'
          borderColor='#9E9E9E'
          txtColor='#444444'
          fontSize='14px'
          styledTextProps={{ fontWeight: '700', letterSpacing: '0' }}
          styledWrapperProps={{
            padding: '8px',
            justifyContent: 'space-between',
          }}
          customBorderRadius='4px'
          onClick={onRequestClose}
        />
      </ButtonContainer>
    </ModalContent>
  );
};

TeamBuildrModal.defaultProps = {
  theme: {},
};

TeamBuildrModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      gymStudio: PropTypes.string.isRequired,
      gymStudioHover: PropTypes.string.isRequired,
      gymStudioHoverLight: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default TeamBuildrModal;
