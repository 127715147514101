/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';
import { animated, useTransition, useSpring } from 'react-spring';

import * as Yup from 'yup';
import moment from 'moment';
import IcomoonReact from 'icomoon-react';

import { Form } from 'formik';
import { has } from 'lodash';
import iconSet from '../../../shared/images/teambuildr-selection.json';

import useConversations from '../components/hooks/useConversations';
import Text from '../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import FormHandler from '../../../shared/components/FormHandler/FormHandler';
import Spinner from '../../../shared/components/Spinner/Spinner';

import CreateConversationSlide1 from './CreateConversationSlide1';
import CreateConversationSlide2 from './CreateConversationSlide2';
import CreateConversationSlide3 from './CreateConversationSlide3';
import CreateConversationSlide4 from './CreateConversationSlide4';
import {
  setActiveConversation,
  setIsMediaUploaded,
} from '../ducks/conversationsActions';
import { TransitionGlobal } from '../../../shared/GlobalStyles';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const ModalWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  margin-bottom: 30px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const MessageIconCircle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding-left: 3px;
  padding-top: 2px;
`;

const InnerFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232, 232, 232);
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 78px;
  min-width: 250px;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
    margin-top: 10px;
  }
`;

const conversationSchema = Yup.object().shape({
  memberGroupingType: Yup.number(),
  selectedRecipients: Yup.array().when('memberGroupingType', {
    is: (memberGroupingType) => memberGroupingType === 1
      || memberGroupingType === 2
      || memberGroupingType === 3,
    then: Yup.array().min(1, 'Please select at least one recipient'),
  }),
});

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const CreateConversationForm = ({ onRequestClose }) => {
  const {
    handleCreateConversation,
    handleCreateSilentConversation,
    handleCreateNewMessage,
    handleSendExternalMessageOnly,
    handleCreateIndividualConversations,
  } = useConversations();
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isCreatingConversation = useSelector(
    (state) => state.conversations.ui.isCreatingConversation,
  );
  const newConversation = useSelector(
    (state) => state.conversations.data.newConversation,
  );
  const activeConversation = useSelector(
    (state) => state.conversations.data.activeConversation,
  );
  const uploadMedia = useSelector(
    (state) => state.conversations.data.uploadMedia,
  );
  const [selectedOptions, setSelectedOptions] = useState({
    tbm: true,
    em: false,
    sms: false,
  });
  const isMediaUploaded = useSelector(
    (state) => state.conversations.data.isMediaUploaded,
  );
  const isMessagesLoading = useSelector(
    (state) => state.conversations.ui.isMessagesLoading,
  );
  const isConversationCreated = useSelector(
    (state) => state.conversations.ui.isConversationCreated,
  );
  const slideRef = useRef();

  const [isGroupSelected, setIsGroupSelected] = useState(true);
  const [hasMessagingOptions, setHasMessagingOptions] = useState(true);
  const [page1Errors, setPage1Errors] = useState(false);

  const formAnimation = useSpring({
    opacity: 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: 0, zIndex: 0 });

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: 'translate3d(25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-25%, 0, 0)' },
  });

  useEffect(() => {
    if (isConversationCreated && !isMessagesLoading) {
      if (activeSlide === 0) {
        if (!selectedOptions.sms && !selectedOptions.em) {
          onRequestClose();
        } else {
          setActiveSlide(1);
        }
      }
    }
  }, [isConversationCreated]);

  const handleSlide1Submit = (values) => {
    const newValues = {};
    newValues.messageBody = values.message;
    newValues.userId = currentUser.id;
    newValues.memberGroupingType = values.memberGroupingType;
    newValues.memberIds = values.selectedRecipients.map((ob) => ob.id);
    if (selectedOptions.em) {
      newValues.sendEmail = true;
    }
    if (selectedOptions.sms) {
      newValues.sendSMS = true;
    }
    if (values.scheduledDeliveryTime) {
      newValues.scheduledDeliveryTime = parseInt(
        moment(values.scheduledDeliveryTime).format('X'),
        10,
      );
    }
    if (isMediaUploaded && uploadMedia) {
      newValues.mediaId = uploadMedia.id;
    }
    if (values.readOnly) {
      newValues.readOnly = values.readOnly;
    }
    if (isGroupSelected) {
      if (!selectedOptions.tbm && (selectedOptions.sms || selectedOptions.em)) {
        handleCreateSilentConversation({
          newConversation: newValues,
          accountCode: currentUser.accountCode,
        });
        setActiveSlide(1);
      }
      if (selectedOptions.tbm) {
        handleCreateConversation({
          newConversation: newValues,
          accountCode: currentUser.accountCode,
        });
      }
    }
    if (!isGroupSelected) {
      setActiveSlide(1);
    }
  };

  const handleSlide2Submit = (values) => {
    if (isGroupSelected) {
      const newMessageObject = {};
      newMessageObject.messageBody = values.message;
      newMessageObject.userId = currentUser.id;
      if (selectedOptions.em) {
        newMessageObject.sendEmail = true;
      }
      if (selectedOptions.sms) {
        newMessageObject.sendSMS = true;
      }
      if (values.scheduledDeliveryTime) {
        newMessageObject.scheduledDeliveryTime = parseInt(
          moment(values.scheduledDeliveryTime).format('X'),
          10,
        );
      }
      if (isMediaUploaded && uploadMedia) {
        newMessageObject.mediaId = uploadMedia.id;
      }
      if (values.readOnly) {
        newMessageObject.readOnly = values.readOnly;
      }
      if (!selectedOptions.tbm && (selectedOptions.sms || selectedOptions.em)) {
        handleSendExternalMessageOnly(
          newMessageObject,
          currentUser.accountCode,
          newConversation.id,
        );
        setActiveSlide(3);
      }
      if (selectedOptions.tbm && (selectedOptions.sms || selectedOptions.em)) {
        const id = newConversation ? newConversation.id : activeConversation.id;
        handleCreateNewMessage(newMessageObject, currentUser.accountCode, id);
        onRequestClose();
      }
    } else {
      const newConversationObject = {};
      newConversationObject.messageBody = values.message;
      newConversationObject.userId = currentUser.id;
      newConversationObject.memberGroupingType = values.memberGroupingType;
      newConversationObject.memberIds = values.selectedRecipients.map(
        (ob) => ob.id,
      );
      if (selectedOptions.em) {
        newConversationObject.sendEmail = true;
      }
      if (selectedOptions.sms) {
        newConversationObject.sendSMS = true;
      }
      if (values.scheduledDeliveryTime) {
        newConversationObject.scheduledDeliveryTime = parseInt(
          moment(values.scheduledDeliveryTime).format('X'),
          10,
        );
      }
      if (isMediaUploaded && uploadMedia) {
        newConversationObject.mediaId = uploadMedia.id;
      }
      if (values.readOnly) {
        newConversationObject.readOnly = values.readOnly;
      }
      handleCreateIndividualConversations({
        newConversation: newConversationObject,
        accountCode: currentUser.accountCode,
      });
      setActiveSlide(2);
    }
  };

  return (
    <>
      <ModalWrapper>
        <NavigationWrapper>
          <Text
            className='close arrow-div'
            onClick={() => {
              onRequestClose();
            }}
          >
            <IcomoonReact iconSet={iconSet} size={13} icon='remove' />
          </Text>
        </NavigationWrapper>
        <FormHandler
          initialValues={{
            message: '',
            memberGroupingType: 1,
            selectedRecipients: [],
            scheduledDeliveryTime: 0,
            readOnly: false,
          }}
          validationSchema={conversationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              if (activeSlide === 0) {
                handleSlide1Submit(values);
                console.log(values);
              } else if (activeSlide === 1) {
                handleSlide2Submit(values);
              }
              setSubmitting(false);
            });
          }}
        >
          {(formProps) => (
            <InnerFormWrapper>
              <FolderTextWrapper>
                <MessageIconCircle>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={30}
                    icon='send-alt'
                    color='black'
                  />
                </MessageIconCircle>
                <Text className='calendar-icon-text'>Compose Message</Text>
                {currentUser.admin === 1 && (
                  <Text fontSize='12px' color='#FF0000'>
                    Athletes must be added to groups to send them messages.
                  </Text>
                )}
                {currentUser.admin !== 1 && !hasMessagingOptions && (
                  <Text fontSize='12px' color='#FF0000'>
                    You must belong to a group in order to send messages.
                  </Text>
                )}
              </FolderTextWrapper>
              <div className='divider-line' />
              <Form style={{ width: '100%' }}>
                <animated.div style={formAnimation}>
                  <SlideContainer height={slideDimensions.height}>
                    {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
                    {slideTransition.map(({ item, props }) => {
                      let returnComponent;
                      if (item === 0) {
                        returnComponent = (
                          <animated.div
                            ref={slideRef}
                            style={{ ...defaultSlideStyle, ...props }}
                          >
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateConversationSlide1
                              formProps={formProps}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              setHasMessagingOptions={setHasMessagingOptions}
                              setIsGroupSelected={setIsGroupSelected}
                              isGroupSelected={isGroupSelected}
                              page1Errors={page1Errors}
                            />
                          </animated.div>
                        );
                      } else if (item === 1) {
                        returnComponent = (
                          <animated.div
                            ref={slideRef}
                            style={{ ...defaultSlideStyle, ...props }}
                          >
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateConversationSlide2
                              formProps={formProps}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              setIsMediaUploaded={setIsMediaUploaded}
                              isMediaUploaded={isMediaUploaded}
                              isGroupSelected={isGroupSelected}
                            />
                          </animated.div>
                        );
                      } else if (item === 2) {
                        returnComponent = (
                          <animated.div
                            ref={slideRef}
                            style={{ ...defaultSlideStyle, ...props }}
                          >
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateConversationSlide3 formProps={formProps} />
                          </animated.div>
                        );
                      } else if (item === 3) {
                        returnComponent = (
                          <animated.div
                            ref={slideRef}
                            style={{ ...defaultSlideStyle, ...props }}
                          >
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateConversationSlide4
                              formProps={formProps}
                              selectedOptions={selectedOptions}
                            />
                          </animated.div>
                        );
                      }
                      return returnComponent;
                    })}
                  </SlideContainer>
                </animated.div>
                <animated.div style={spinnerAnimation}>
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                </animated.div>
                {activeSlide === 1 && (
                  <NoHoverButton
                    onClick={() => {
                      formProps.setSubmitting(true);
                    }}
                    cta='Submit'
                    type='submit'
                    fullWidth
                    className='modal-button'
                    customColor='#0dcc8a'
                    noBorder
                    large
                    square
                    primary
                    bottom
                  />
                )}
                {activeSlide === 0 && (
                  <NoHoverButton
                    onClick={() => {
                      formProps.validateForm().then((errors) => {
                        const page1Fields = ['selectedRecipients'];
                        if (
                          Object.keys(errors).some((item) => page1Fields.includes(item))
                        ) {
                          setPage1Errors(true);
                        } else {
                          formProps.setSubmitting(true);
                        }
                      });
                    }}
                    type='submit'
                    cta={
                      selectedOptions.tbm
                      || selectedOptions.em
                      || selectedOptions.sms
                        ? 'Next'
                        : 'Please Select a Message Option'
                    }
                    disabled={
                      (!selectedOptions.tbm
                        && !selectedOptions.em
                        && !selectedOptions.sms)
                      || isCreatingConversation
                    }
                    fullWidth
                    className='modal-button'
                    customColor='#0dcc8a'
                    noBorder
                    large
                    square
                    primary
                    bottom
                  />
                )}
                {activeSlide === 2 && (
                  <NoHoverButton
                    onClick={() => {
                      onRequestClose();
                    }}
                    type='submit'
                    cta='Close'
                    fullWidth
                    className='modal-button'
                    customColor='#0dcc8a'
                    noBorder
                    large
                    square
                    primary
                    bottom
                  />
                )}
              </Form>
            </InnerFormWrapper>
          )}
        </FormHandler>
      </ModalWrapper>
    </>
  );
};

CreateConversationForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default CreateConversationForm;
