import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { setActiveGymStudioModal, clearGymStudioError } from '../../ducks/gymStudioActions';
import Modal from '../../../../shared/components/Modal/Modal';
import GymConnectModal from './GymConnectModal';
import InfoModal from './InfoModal';
import DeleteConnectionModal from '../../gym-overview-dashboard/components/presentational/DeleteConnectionModal';
import ExportUsersModal from '../../gym-overview-dashboard/components/presentational/ExportUsersModal';

const MainContainer = styled('main')``;

const ModalHandler = ({ setIsDropdownOpen }) => {
  const dispatch = useDispatch();

  const {
    activeModal,
    selectedTab,
    isSideBarExpanded,
  } = useSelector((state) => state.gymStudio.ui);
  const { isStudioConnected } = useSelector((state) => state.gymStudio.data);

  const closeGymConnectModal = () => {
    if (selectedTab === 'quick-statistics') {
      // Navigate to Gym Overview when in Quick Stats
      batch(() => {
        dispatch(clearGymStudioError());
        dispatch(push('/gymstudio/gym-overview'));
        dispatch(setActiveGymStudioModal(''));
      });
    } else {
      batch(() => {
        dispatch(clearGymStudioError());
        dispatch(setActiveGymStudioModal(''));
      });
    }
  };

  const closeBasicModal = () => {
    batch(() => {
      dispatch(setActiveGymStudioModal(''));
      if (setIsDropdownOpen) setIsDropdownOpen(false);
    });
  };

  const renderModalContent = () => {
    if (activeModal === 'gym-connect' && isStudioConnected) {
      return null;
    }

    switch (activeModal) {
      case 'gym-connect':
        return (
          <GymConnectModal
            onRequestClose={closeGymConnectModal}
            selectedTab={selectedTab}
          />
        );
      case 'delete-connection':
        return <DeleteConnectionModal onRequestClose={closeBasicModal} />;
      case 'info':
        return <InfoModal onRequestClose={closeBasicModal} />;
      case 'export-users':
        return <ExportUsersModal onRequestClose={closeBasicModal} />;
      default:
        return null;
    }
  };

  const modalProps = {
    isOpen: activeModal === 'gym-connect' ? !isStudioConnected : !!activeModal,
    onRequestClose: () => {
      if (activeModal === 'gym-connect' && selectedTab === 'quick-statistics') {
        return;
      }
      dispatch(setActiveGymStudioModal(''));
    },
    customBorderRadius: '12px',
    hideButton: true,
    shouldCloseOnOverlayClick: !(activeModal === 'gym-connect' && selectedTab === 'quick-statistics'),
  };

  const sidebarWidth = isSideBarExpanded ? 300 : 96;
  // Width adjustments for specific modals
  if (activeModal === 'info') {
    modalProps.customMaxWidth = `calc(95vw - ${sidebarWidth}px)`;
    modalProps.customMaxHeight = '460px';
  } else {
    modalProps.customMaxWidth = 525;
  }
  // Crops the overlay to the sidebar width and topnav height
  if (activeModal === 'gym-connect' || activeModal === 'info') {
    modalProps.sidebarWidth = sidebarWidth;
    modalProps.headerHeightToSubtract = 49;
  }

  return (
    <MainContainer>
      {activeModal && !(activeModal === 'gym-connect' && isStudioConnected) && (
        <Modal {...modalProps} widthFitContent>
          {renderModalContent()}
        </Modal>
      )}
    </MainContainer>
  );
};

ModalHandler.propTypes = {
  setIsDropdownOpen: PropTypes.func.isRequired,
};

export default ModalHandler;
