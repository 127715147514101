import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import bannerBackground from '../../../../../shared/images/marketing-banner.jpg';
import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import Text from '../../../../../shared/components/Text/Text';

const BannerContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  min-height: 156px;
  border-radius: 12px;
  overflow: hidden;
  
  /* Background image */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${bannerBackground}) center/cover no-repeat;
    opacity: 1;
    z-index: 1;
  }

  /* Overlay colour */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(228, 237, 255, 0.6);
    z-index: 2;
  }
`;

const BannerContent = styled('div')`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 8px;
`;

const PreviewButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: 170px;
  border: none;
  border-radius: 30px;
  background-color: rgba(6, 40, 61, 0.2);
  color: #06283D;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  gap: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(6, 40, 61, 0.3);
  }
`;

const CircleIcon = styled('span')`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #5F8BDC;
  border-radius: 50%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); 
`;

const MarketingBanner = ({ style, onPreviewClick }) => (
  <BannerContainer style={style}>
    <BannerContent>
      <TextContainer>
        <HeaderText
          fontSize='24px'
          fontWeight='900'
          letterSpacing='normal'
        >
          Quick statistics
        </HeaderText>
        <Text fontSize='12px' fontWeight='600'>
          View class schedules, member bookings, and cancellations
          statistics right inside TeamBuildr!
        </Text>
      </TextContainer>
      <PreviewButton onClick={onPreviewClick}>
        <CircleIcon />
        SEE A PREVIEW
      </PreviewButton>
    </BannerContent>
  </BannerContainer>
);

MarketingBanner.propTypes = {
  style: PropTypes.shape({}),
  onPreviewClick: PropTypes.func.isRequired,
};

MarketingBanner.defaultProps = {
  style: {},
};

export default MarketingBanner;
