export const SET_SIDE_BAR_OPEN = 'SET_SIDE_BAR_OPEN';
export const SET_IS_SIDE_BAR_EXPANDED = 'SET_IS_SIDE_BAR_EXPANDED';

export const FETCH_CONNECTION_DATA_START = 'FETCH_CONNECTION_DATA_START';
export const FETCH_CONNECTION_DATA_FULFILLED = 'FETCH_CONNECTION_DATA_FULFILLED';
export const FETCH_CONNECTION_DATA_REJECTED = 'FETCH_CONNECTION_DATA_REJECTED';

export const UPDATE_MATCHED_USERS_START = 'UPDATE_MATCHED_USERS_START';
export const UPDATE_MATCHED_USERS_FULFILLED = 'UPDATE_MATCHED_USERS_FULFILLED';
export const UPDATE_MATCHED_USERS_REJECTED = 'UPDATE_MATCHED_USERS_REJECTED';

export const CONNECT_GYMSTUDIO_ACCOUNT_START = 'CONNECT_GYMSTUDIO_ACCOUNT_START';
export const CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED = 'CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED';
export const CONNECT_GYMSTUDIO_ACCOUNT_REJECTED = 'CONNECT_GYMSTUDIO_ACCOUNT_REJECTED';

export const FETCH_QUICK_STATS_START = 'FETCH_QUICK_STATS_START';
export const FETCH_QUICK_STATS_FULFILLED = 'FETCH_QUICK_STATS_FULFILLED';
export const FETCH_QUICK_STATS_REJECTED = 'FETCH_QUICK_STATS_REJECTED';

export const DELETE_GYMSTUDIO_CONNECTION_START = 'DELETE_GYM_STUDIO_CONNECTION_START';
export const DELETE_GYMSTUDIO_CONNECTION_FULFILLED = 'DELETE_GYM_STUDIO_CONNECTION_FULFILLED';
export const DELETE_GYMSTUDIO_CONNECTION_REJECTED = 'DELETE_GYM_STUDIO_CONNECTION_REJECTED';

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_ACTIVE_GYM_STUDIO_MODAL = 'SET_ACTIVE_GYM_STUDIO_MODAL';
export const CLEAR_CONNECT_MODAL_ERROR = 'CLEAR_CONNECT_MODAL_ERROR';
