import {
  CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED,
  CONNECT_GYMSTUDIO_ACCOUNT_REJECTED,
  UPDATE_MATCHED_USERS_FULFILLED,
  UPDATE_MATCHED_USERS_REJECTED,
  FETCH_CONNECTION_DATA_FULFILLED,
  FETCH_CONNECTION_DATA_REJECTED,
  FETCH_QUICK_STATS_FULFILLED,
  FETCH_QUICK_STATS_REJECTED,
  DELETE_GYMSTUDIO_CONNECTION_FULFILLED,
  DELETE_GYMSTUDIO_CONNECTION_REJECTED,
} from './actionTypes';

const initialState = {
  connectionData: null,
  users: {
    newMatchedUsers: [],
    existingMatchedUsers: [],
    missingGsUsers: [],
    missingTbUsers: [],
    suspendedUsers: [],
  },
  quickStats: {
    totalNewMembers: 0,
    totalSignIns: 0,
    totalCancellations: 0,
  },
  schedule: [],
  isStudioConnected: false,
  connectionError: null,
  showNewUsersCard: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED:
      return {
        ...state,
        connectionData: action.payload,
        isStudioConnected: !!action.payload.connectedAt,
        connectionError: null,
        showNewUsersCard: false,
      };
    case CONNECT_GYMSTUDIO_ACCOUNT_REJECTED:
      return {
        ...state,
        connectionError: action.payload,
      };
    case UPDATE_MATCHED_USERS_FULFILLED:
      return {
        ...state,
        users: {
          ...state.users,
          newMatchedUsers: action.payload.newMatchedUsers,
          existingMatchedUsers: action.payload.existingMatchedUsers,
          missingGsUsers: action.payload.missingGsUsers,
          missingTbUsers: action.payload.missingTbUsers,
          suspendedUsers: action.payload.suspendedUsers,
        },
        showNewUsersCard: true,
      };
    case UPDATE_MATCHED_USERS_REJECTED:
      return {
        ...state,
        connectionError: action.payload,
      };

    case FETCH_CONNECTION_DATA_FULFILLED:
      return {
        ...state,
        connectionData: action.payload.connection,
        users: {
          ...state.users,
          ...action.payload.users,
          newMatchedUsers: state.users.newMatchedUsers,
        },
        isStudioConnected: !!action.payload.connection?.connectedAt,
      };
    case FETCH_CONNECTION_DATA_REJECTED:
      return {
        ...state,
        connectionError: action.payload,
      };

    case FETCH_QUICK_STATS_FULFILLED:
      return {
        ...state,
        quickStats: {
          totalNewMembers: action.payload.quickStats?.totalNewMembers ?? 0,
          totalSignIns: action.payload.quickStats?.totalSignIns ?? 0,
          totalCancellations: action.payload.quickStats?.totalCancellations ?? 0,
        },
        schedule: action.payload.schedule || [],
      };

    case FETCH_QUICK_STATS_REJECTED:
      return {
        ...state,
        quickStats: {
          totalNewMembers: 0,
          totalSignIns: 0,
          totalCancellations: 0,
        },
        schedule: [],
        connectionError: action.payload,
      };

    case DELETE_GYMSTUDIO_CONNECTION_FULFILLED:
      return {
        ...state,
        connectionData: null,
        isStudioConnected: false,
        connectionError: null,
      };
    case DELETE_GYMSTUDIO_CONNECTION_REJECTED:
      return {
        ...state,
        connectionError: action.payload,
      };

    default:
      return state;
  }
}
