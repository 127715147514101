import styled from '@emotion/styled';
import {
  space, color, layout, flexbox,
} from 'styled-system';

const Wrapper = styled('div')`
${color}
${flexbox}
height: ${(props) => props.height};
${layout}
${space}
width: ${(props) => props.width};
`;

export default Wrapper;
