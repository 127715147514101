/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import ResizeObserver from 'react-resize-observer';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Button from '../Button/Button';
import Title from '../Title/Title';
import Text from '../Text/Text';
import {
  BorderRadius,
  BoxShadow,
  TransitionGlobal,
  Gutter,
} from '../../GlobalStyles';

const getModalStyle = (minHeight = '400px', customBorderRadius = BorderRadius, customMaxHeight, customMaxWidth = '650px', widthFitContent, overlayBackgroundColor) => ({
  overlay: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: overlayBackgroundColor,
    alignItems: 'center',
    position: 'fixed',
    zIndex: '100',
    bottom: 0,
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: `${widthFitContent ? '' : '60vw'}`,
    minHeight,
    minWidth: `${widthFitContent ? '' : '440px'}`,
    maxHeight: customMaxHeight,
    maxWidth: customMaxWidth,
    backgroundColor: 'white',
    borderRadius: customBorderRadius,
    boxShadow: `${BoxShadow}`,
    marginTop: '40px',
    margin: '20px',
  },
});

export const ModalContentWrapper = styled('div')`
  display: flex;
  padding: ${(props) => props.customPadding || '40px'};
  flex-grow: 1;
  flex-grow: ${(props) => (props.flexGrow ? 1 : 0)};
  background-color: ${(props) => props.theme.backgroundColorL2};
  border-radius: ${(props) => props.customBorderRadius || 'initial'};
  form {
    padding-bottom: 20px;
    label {
      color: ${(props) => props.theme.textColor};
    }
    input, textarea {
      background-color: ${(props) => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${(props) => props.theme.backgroundColorL3};
    color: ${(props) => props.theme.textColor};
    border-color: ${(props) => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${(props) => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  /* .form-group {
    padding: ${Gutter} calc(${Gutter} * 3);
  } */
  /* .form-control {
    min-height: 150px;
  } */
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    left: 0px;
    bottom: 0;
    color: ${(props) => (props.customButton ? 'white !important' : '')};
    border-bottom-left-radius: ${(props) => props.customBorderRadius};
    border-bottom-right-radius: ${(props) => props.customBorderRadius}
  }

  .modal-button-custom {
    width: 50%;
    margin-left: 0;
  }

  .modal-button-ok {
    right: 0;
  }

  .modal-button-cancel {
    left: 0;
  }

  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: ${(props) => props.customPadding || '20px'};

    textarea {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;

    textarea {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }

  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }

  @media screen and (max-width: 767px) {
    padding: ${(props) => props.customPadding || '20px'};
    
    textarea {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
`;

const TitleContainer = styled('div')`
  width: 100%;
  border-bottom: ${(props) => (props.titleBorderBottomStyle ? props.titleBorderBottomStyle : '1px solid #979797')};
  padding-top: 40px; 

  @media only screen and (max-width: 767px) {
    padding-top: 20px;
  }
`;

const Modal = ({
  children,
  customButton,
  isOpen,
  onRequestClose,
  noOverlay,
  title,
  subtitle,
  titleIcon,
  sidebarWidth,
  headerHeightToSubtract,
  customPadding,
  customMinHeight,
  customBorderRadius,
  customMaxHeight,
  customMaxWidth,
  hideButton,
  hideTitle,
  flexGrow,
  widthFitContent,
  overlayBackgroundColor,
  titleBorderBottomStyle,
  closeOnClickOutside,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
}) => {
  const [modalHeightDimension, setModalHeightDimension] = useState({});
  const [modalTitleHeight, setModalTitleHeight] = useState({});
  const modalContentRef = useRef(null);
  const modalRef = useRef(null);
  const titleContainerRef = useRef(null);
  const theme = useTheme();

  const modalClose = () => {
    onRequestClose();
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current
      && !modalRef.current.contains(event.target)
    ) {
      modalClose();
    }
  };

  /** Add event listener that will close modal if the user clicks anywhere outside the
   * modal content, not just the overlay
   * for example if I dont want the overlay to cover the side bar nav for the sports science
   * hub but I still want the modal to close when I click outside the modal
   * then I can pass in this prop closeOnClickOutside * */
  useEffect(() => {
    if (isOpen && closeOnClickOutside) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, closeOnClickOutside]);

  useEffect(() => {
    if (titleContainerRef?.current) {
      setModalTitleHeight(titleContainerRef.current.getBoundingClientRect().height);
    }
  }, [subtitle, title]);

  const modalStyle = getModalStyle(
    customMinHeight,
    customBorderRadius,
    customMaxHeight,
    customMaxWidth,
    widthFitContent,
    overlayBackgroundColor,
  );

  return (
    <>
      <Global
        styles={css`
          .ReactModal__Content {
            opacity: 0;
            transform: translateY(50%);

            &::-webkit-scrollbar {
                display: none;
              }

              /* For Firefox */
              scrollbar-width: none;

              /* For IE and Edge */
              -ms-overflow-style: none;
            
            &:focus {
              outline-color: -webkit-focus-ring-color!important;
              outline-style: auto!important;
              outline-width: 0px!important;
              outline: none!important;
            }
            @media screen and (max-width: 580px) {
              // min-width: 350px!important;
              min-width: ${widthFitContent ? '' : '350px!important'};
              padding-bottom: ${customPadding || '60px'};
            }

          }

          .ReactModal__Overlay {
            background-color: ${noOverlay ? 'transparent' : overlayBackgroundColor}!important;
            // background-color: rgba(68, 68, 68, 0.6);
            opacity: 1;
            z-index: 1000!important;
            justify-content: center;
            width: calc(100vw - ${sidebarWidth && `${sidebarWidth}px`})!important;
            height: calc(100vh - ${headerHeightToSubtract && `${headerHeightToSubtract}px`})!important;
            margin-left: ${sidebarWidth && `${sidebarWidth}px`}!important;

            /* @media only screen 
            and (min-device-width : 768px) 
            and (max-device-width : 1024px) 
            and (orientation : portrait) {
              margin-left: ${sidebarWidth && ''}
            } */

            @media screen and (max-width: 767px) {
              margin-left: 0px!important;
              width: 100vw!important;
            }
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transition: ${TransitionGlobal};
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translateY(0);
            transition: ${TransitionGlobal};
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translateY(50%);
          }

          // @media screen and (max-height: ${title ? modalHeightDimension + 194 : modalHeightDimension}px) {
            @media screen and (max-height: ${title ? modalHeightDimension + (modalTitleHeight + 80) : modalHeightDimension}px) {
            .ReactModal__Content {
              position: absolute!important;
              top: 15px!important;
            }
          }

          @media only screen and (max-device-width: 767px) {
            .ReactModal__Content {
              // min-width: 95%!important;
              min-width: ${widthFitContent ? '' : '95%!important'};
            }
          }
        `}
      />
      <ReactModal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        contentRef={(node) => { modalRef.current = node; }}
        closeTimeoutMS={250}
        isOpen={isOpen}
        onRequestClose={modalClose}
        style={modalStyle}
        className='global-modal'
        overlayClassName='overlay'
      >
        {title && !hideTitle && (
          <TitleContainer titleBorderBottomStyle={titleBorderBottomStyle} ref={titleContainerRef}>
            <ResizeObserver
              onResize={(rect) => {
                setModalTitleHeight(rect?.height);
              }}
            />
            <Title icon={titleIcon}>
              {title}
              {subtitle && (
                <Text style={{
                  marginTop: '5px', fontWeight: 600, wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal', paddingLeft: '20px', paddingRight: '20px',
                }}
                >
                  {subtitle}
                </Text>
              )}
            </Title>
          </TitleContainer>
        )}
        <ModalContentWrapper
          customPadding={customPadding}
          ref={modalContentRef}
          customBorderRadius={customBorderRadius}
          flexGrow={flexGrow}
          customButton={customButton}
        >
          <ResizeObserver
            onResize={(rect) => {
              setModalHeightDimension(rect?.height);
            }}
          />
          {children}
          {!customButton && !hideButton && (
            <Button
              bottom
              fullWidth
              cta='Close'
              className='modal-button'
              large
              noBorder
              customColor={theme.colors.green}
              primary
              square
              onClick={modalClose}
              customTextColor='white'
            />
          )}
        </ModalContentWrapper>
      </ReactModal>
    </>
  );
};

if (process.env.STORYBOOK_RUNNING === undefined) {
  ReactModal.setAppElement('#app');
}

Modal.propTypes = {
  children: PropTypes.node,
  customButton: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  customPadding: PropTypes.string,
  headerHeightToSubtract: PropTypes.number,
  customBorderRadius: PropTypes.string,
  hideButton: PropTypes.bool,
  hideTitle: PropTypes.bool,
  flexGrow: PropTypes.bool,
  widthFitContent: PropTypes.bool,
  overlayBackgroundColor: PropTypes.string,
  titleBorderBottomStyle: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  subtitle: PropTypes.string,
};

Modal.defaultProps = {
  children: '',
  customButton: false,
  customPadding: '40px',
  headerHeightToSubtract: 0,
  customBorderRadius: 'initial',
  hideButton: false,
  hideTitle: false,
  flexGrow: true,
  widthFitContent: false,
  overlayBackgroundColor: 'rgba(216, 216, 216, 0.5)',
  titleBorderBottomStyle: '1px solid #979797',
  closeOnClickOutside: false,
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  subtitle: '',
};

export default Modal;
