// Authored by Ozan Sozuoz
import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../images/teambuildr-selection.json';
import Wrapper from './Wrapper';
import TBTooltip from '../Tooltip';

/**
 * Icon renders an icon from Icomoon with customizable size and color.
 *
 * @param {String} color The color of the icon
 * @param {String} icon The name of the icon to be displayed
 * @param {String|Number} size The size of the icon
 * @param {Object} styledProps Additional styles for the wrapper
 * @param {String} tooltipId The ID of the tooltip
 * @param {String} tooltipPlace The position of the tooltip
 * @param {Object} tooltipStyles Additional styles for the tooltip
 * @param {String} tooltipText The text to be displayed in the tooltip
 * @returns {JSX.Element} The rendered Icon component
 */
const Icon = ({
  color,
  icon,
  size,
  styledProps,
  tooltipId,
  tooltipPlace,
  tooltipStyles,
  tooltipText,
}) => {
  const iconElement = (
    <IcomoonReact iconSet={iconSet} icon={icon} size={size} color={color} />
  );

  return (
    <Wrapper {...styledProps}>
      {tooltipText && tooltipId ? (
        <TBTooltip
          hoverText={tooltipText}
          id={tooltipId}
          place={tooltipPlace}
          tooltipStyles={tooltipStyles}
          icon={icon}
          iconSize={size}
        >
          {iconElement}
        </TBTooltip>
      ) : (
        iconElement
      )}
    </Wrapper>
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styledProps: PropTypes.instanceOf(Object),
  tooltipId: PropTypes.string,
  tooltipPlace: PropTypes.string,
  tooltipStyles: PropTypes.instanceOf(Object),
  tooltipText: PropTypes.string,
};

Icon.defaultProps = {
  color: 'currentColor',
  size: '100%',
  styledProps: {},
  tooltipId: '',
  tooltipPlace: 'top',
  tooltipStyles: {},
  tooltipText: '',
};

export default Icon;
