import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from '../../../../../shared/_TBComponents/Icon';

const CardContainer = styled('div')`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 
    0px 1px 1px rgba(8, 5, 10, 0.08),
    0px 1px 4px rgba(8, 5, 10, 0.10); 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
`;

const IconWrapper = styled('div')`
  width: 56px;
  height: 56px;
  background-color: ${({ iconBgColor }) => iconBgColor || '#E5E5E5'};
  border: 4px solid ${({ borderColor }) => borderColor || 'rgba(255, 255, 255, 0.6)'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
`;

const StatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.0;
`;

const DataNumber = styled('div')`
  font-size: 36px;
  font-weight: 900;
  color: #444444;
  margin-bottom: 4px;
  font-family: 'Nunito Sans', sans-serif;
`;

const DataText = styled('p')`
  font-size: 12px;
  font-weight: 700;
  color: #616161;
  margin-bottom: 2px;
  font-family: 'Nunito Sans', sans-serif;
`;

const UserStatsCard = ({
  icon, iconSize, iconBgColor, iconColor, borderColor, number, text,
}) => (
  <CardContainer>
    <IconWrapper iconBgColor={iconBgColor} borderColor={borderColor}>
      <Icon icon={icon} size={iconSize} color={iconColor} />
    </IconWrapper>
    <StatsContainer>
      {number !== null && number !== undefined && (
        <DataNumber>{number}</DataNumber>
      )}
      <DataText>{text}</DataText>
    </StatsContainer>
  </CardContainer>
);

UserStatsCard.propTypes = {
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  iconBgColor: PropTypes.string,
  borderColor: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
};

UserStatsCard.defaultProps = {
  iconColor: '#FFFFFF',
  iconSize: 24,
  iconBgColor: '#E5E5E5',
  borderColor: 'rgba(255, 255, 255, 0.6)',
};

export default UserStatsCard;
