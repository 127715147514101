import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from '../../../../shared/components/Button/Button';
import InfoImage1 from '../../../../shared/images/info-modal-1.png';
import InfoImage2 from '../../../../shared/images/info-modal-2.png';
import InfoImage3 from '../../../../shared/images/info-modal-3.png';

const ModalContent = styled('div')`
  display: flex;
  width: 95vw;
  gap: 32px;
  justify-content: space-between;
`;

const ButtonWrapper = styled('div')`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;

  & svg path {
    fill: '#444444' !important;
  }
`;

const CardContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  padding: 16px;
`;

const Card = styled('div')`
  width: 31%;
  height: 350px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
`;

const CardImage = styled('div')`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0 0;
`;

const FlexContainer = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px;
`;

const CardTitle = styled('h3')`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  word-break: break-word;
  font-family: 'Nunito Sans', sans-serif;
`;

const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
  margin: 12px 0;
`;

const CardDescription = styled('p')`
  font-size: 14px;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  height: 4.5em;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
`;

const InfoModal = ({ onRequestClose }) => (
  <ModalContent>
    <ButtonWrapper>
      <Button
        primary
        iconOnly
        icon='remove'
        onClick={onRequestClose}
      />
    </ButtonWrapper>
    <CardContainer>
      <Card>
        <CardImage style={{ backgroundImage: `url(${InfoImage1})` }} />
        <FlexContainer>
          <CardTitle>Gym Overview & Statistics</CardTitle>
          <Divider />
          <CardDescription>
            Sync your gym&apos;s data and get instant access to personalized statistics in seconds
          </CardDescription>
        </FlexContainer>
      </Card>
      <Card>
        <CardImage style={{ backgroundImage: `url(${InfoImage2})` }} />
        <FlexContainer>
          <CardTitle>Member Workout Access</CardTitle>
          <Divider />
          <CardDescription>
            Connect workout access in TeamBuildr to successful payment status in GymStudio
          </CardDescription>
        </FlexContainer>
      </Card>
      <Card>
        <CardImage style={{ backgroundImage: `url(${InfoImage3})` }} />
        <FlexContainer>
          <CardTitle>Member Booking & Scheduling</CardTitle>
          <Divider />
          <CardDescription>
            Your users will be able to view and book classes through the TeamBuildr app
          </CardDescription>
        </FlexContainer>
      </Card>
    </CardContainer>
  </ModalContent>
);

InfoModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default InfoModal;
