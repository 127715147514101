import React from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';

import { connectGymStudioAccount, setActiveGymStudioModal, clearGymStudioError } from '../../ducks/gymStudioActions';
import Icon from '../../../../shared/_TBComponents/Icon';

const ModalContent = styled('div')`
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
`;

const Title = styled('h2')`
  font-size: 24px;
  font-weight: 900;
  color: #444444;
  margin-bottom: 16px;
  text-align: left;
`;

const Description = styled('p')`
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  margin-bottom: 24px;
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;

  a {
    color: #007aff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #9E9E9E;
  margin: 20px 0;
`;

const InputLabel = styled('label')`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  display: block;
  text-align: left;
  margin-bottom: 8px;
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => (props.error ? '#CC0404' : '#9E9E9E')};
  border-radius: 4px;
  font-size: 14px;
  color: #444444;
  box-shadow: 
    0 1px 2px rgba(81, 81, 81, 0.2),
    inset 0 2px 4px rgba(98, 98, 98, 0.16);
  
  &:focus {
    border-color: ${(props) => (props.error ? '#CC0404' : '#444444')};
  }
`;

const ErrorText = styled('div')`
  color: #CC0404;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 700;
  margin-top: 4px;
  text-align: left;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const ConnectButton = styled('button')`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.theme.colors.gymStudio)};
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #e67300;
  }
`;

const BackButton = styled('button')`
  background: transparent;
  color: #5F8BDC;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
`;

const GymConnectModal = ({ onRequestClose, selectedTab }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const errorMessage = useSelector((state) => state.gymStudio.ui.errorMessage);

  const validationSchema = Yup.object({
    apiKey: Yup.string().required('API key is required'),
  });

  // Switch to gym overview tab when in quick stats
  const handleBackButtonClick = () => {
    if (selectedTab === 'quick-stats') {
      batch(() => {
        dispatch(clearGymStudioError());
        dispatch(push('/gymstudio/gym-overview'));
        dispatch(setActiveGymStudioModal('gym-connect'));
      });
    } else {
      dispatch(clearGymStudioError());
      onRequestClose();
    }
  };

  return (
    <ModalContent>
      <Title>Allow TeamBuildr to access your GymStudio Account?</Title>
      <Description>
        Athletes must use the same email address for both TeamBuildr and GymStudio
        to view shared data across the platforms.
        Please note that accounts created with Apple or Google login will not be able
        to connect unless an additional email is
        added that matches TeamBuildr & GymStudio.
        {/* <a>Read more.</a> */}
      </Description>

      <Formik
        initialValues={{ apiKey: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(connectGymStudioAccount(currentUser?.accountCode, values.apiKey));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <InputLabel htmlFor='apiKey'>Enter GymStudio API Key</InputLabel>
            <Divider />
            <InputField
              id='apiKey'
              name='apiKey'
              type='text'
              placeholder='Enter API Key here'
              error={errors.apiKey && touched.apiKey}
            />
            {errors.apiKey && touched.apiKey ? (
              <ErrorText>{errors.apiKey}</ErrorText>
            ) : (
              errorMessage && <ErrorText>{errorMessage}</ErrorText>
            )}
            <ButtonContainer>
              <ConnectButton type='submit'>
                Yes, connect GymStudio
                <Icon
                  size={12}
                  icon='altrightarrow'
                  color='white'
                  styledProps={{ marginLeft: '12px' }}
                />
              </ConnectButton>
              <BackButton type='button' onClick={handleBackButtonClick}>
                Go back
              </BackButton>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

GymConnectModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export default GymConnectModal;
