import {
  SET_IS_SIDE_BAR_EXPANDED,
  CONNECT_GYMSTUDIO_ACCOUNT_START,
  CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED,
  CONNECT_GYMSTUDIO_ACCOUNT_REJECTED,
  UPDATE_MATCHED_USERS_START,
  UPDATE_MATCHED_USERS_FULFILLED,
  UPDATE_MATCHED_USERS_REJECTED,
  FETCH_CONNECTION_DATA_START,
  FETCH_CONNECTION_DATA_FULFILLED,
  FETCH_CONNECTION_DATA_REJECTED,
  FETCH_QUICK_STATS_START,
  FETCH_QUICK_STATS_FULFILLED,
  FETCH_QUICK_STATS_REJECTED,
  DELETE_GYMSTUDIO_CONNECTION_START,
  DELETE_GYMSTUDIO_CONNECTION_FULFILLED,
  DELETE_GYMSTUDIO_CONNECTION_REJECTED,
  SET_SELECTED_TAB,
  SET_ACTIVE_GYM_STUDIO_MODAL,
  CLEAR_CONNECT_MODAL_ERROR,
} from './actionTypes';

const initialState = {
  isSideBarExpanded: true,
  loadingConnectionData: false,
  connectingGymStudio: false,
  updatingMatchedUsers: false,
  loadingQuickStats: false,
  deletingGymStudioConnection: false,
  activeModal: '',
  selectedTab: '',
  errorMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_SIDE_BAR_EXPANDED:
      return { ...state, isSideBarExpanded: action.payload };

    case CONNECT_GYMSTUDIO_ACCOUNT_START:
      return {
        ...state,
        connectingGymStudio: true,
        errorMessage: null,
      };
    case CONNECT_GYMSTUDIO_ACCOUNT_FULFILLED:
      return {
        ...state,
        errorMessage: null,
      };
    case CONNECT_GYMSTUDIO_ACCOUNT_REJECTED:
      return {
        ...state,
        connectingGymStudio: false,
        errorMessage: action.payload,
      };

    case UPDATE_MATCHED_USERS_START:
      return { ...state, updatingMatchedUsers: true };
    case UPDATE_MATCHED_USERS_FULFILLED:
      return { ...state, updatingMatchedUsers: false };
    case UPDATE_MATCHED_USERS_REJECTED:
      return { ...state, updatingMatchedUsers: false };

    case FETCH_CONNECTION_DATA_START:
      return { ...state, loadingConnectionData: true };
    case FETCH_CONNECTION_DATA_FULFILLED:
      return { ...state, loadingConnectionData: false };
    case FETCH_CONNECTION_DATA_REJECTED:
      return { ...state, loadingConnectionData: false };

    case FETCH_QUICK_STATS_START:
      return { ...state, loadingQuickStats: true };
    case FETCH_QUICK_STATS_FULFILLED:
      return { ...state, loadingQuickStats: false };
    case FETCH_QUICK_STATS_REJECTED:
      return { ...state, loadingQuickStats: false };

    case DELETE_GYMSTUDIO_CONNECTION_START:
      return { ...state, deletingGymStudioConnection: true };
    case DELETE_GYMSTUDIO_CONNECTION_FULFILLED:
      return { ...state, deletingGymStudioConnection: false };
    case DELETE_GYMSTUDIO_CONNECTION_REJECTED:
      return { ...state, deletingGymStudioConnection: false };

    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_ACTIVE_GYM_STUDIO_MODAL:
      return {
        ...state,
        activeModal: action.payload,
      };
    case CLEAR_CONNECT_MODAL_ERROR:
      return {
        ...state,
        errorMessage: null,
      };

    default:
      return state;
  }
}
