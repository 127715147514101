// a relatively complex component that displays a different modal depending on which
// document type the user intends to create
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

import Text from '../../../../../shared/components/Text/Text';
import Button from '../../../../../shared/components/Button/Button';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import useReseller from '../../hooks/useReseller';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
  }
`;

const FieldError = styled('div')`
  font-size: 13px;
  color: #ff6600;
  margin-top: 5px;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const AltFieldWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const DropZoneWrapper = styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;

  /* width: 160px; */
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }
  
  .update-button {
    width: 170px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      color: white;
      opacity: .7;
    }
  }
`;

const FormGroup = styled('div')`
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .limit-text {
    font-size: 11px;
    strong {
      font-size: 16px;
    }
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  .spinner-container {
    min-height: 100px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .alt-form-control {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    opacity: 1;
  }
  .btow {
    font-size: 12px;
    font-weight: 800;
    width: 100%;
    height: 100%;
    min-width: 215px;
    color: white;
    background: black;
    padding: 9px;
    padding-left: 15px;
    border-radius: 0.25rem;
    border: 1px solid black;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const DropZoneDivUploadedFail = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 35px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 160px;
  height: 35px;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .file-icon-text-uploaded-fail {
    font-size: 12px;
    color: red;
  }
  .file-icon-text-uploaded-fail-restart {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
`;

const statusOptions = [
  {
    name: 'Inactive',
    id: 0,
  },
  {
    name: 'Active',
    id: 1,
  },
];

const UpdateProgramSlide1 = ({
  formProps,
  program,
  urlHandled,
  setUrlHandled,
  isMediaUploaded,
  setIsMediaUploaded,
  userProfilePic,
  setUserProfilePic,
  page1Errors,
}) => {
  const {
    currentReseller,
    handleUploadMedia,
    currentUser,
  } = useReseller();

  const uploadedMedia = useSelector((state) => state.reseller.data.uploadMedia);
  const uploadedMediaError = useSelector((state) => state.reseller.data.uploadMediaError);
  const mediaIsUploading = useSelector((state) => state.reseller.ui.isMediaUploading);

  // handler for file dropped (uploaded)
  const handleDroppedFile = (acceptedFile) => {
    handleUploadMedia({ accountCode: currentUser.accountCode, acceptedFile });
    setIsMediaUploaded(true);
  };

  const DropZoneDiv = styled('div')`
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    cursor: pointer;
    color: #495057;
    background-image: url(${(uploadedMedia && isMediaUploaded) ? uploadedMedia.mediaUrl : userProfilePic});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `;

  const fileAccepterSwitch = () => {
    if (!mediaIsUploading) {
      if (uploadedMediaError && isMediaUploaded) {
        return (
          <DropZoneDivUploadedFail>
            <Text className='file-icon-text-uploaded-fail'>Something went wrong</Text>
            <Text
              onClick={() => setIsMediaUploaded(false)}
              className='file-icon-text-uploaded-fail-restart'
            >
              Try Again
            </Text>
          </DropZoneDivUploadedFail>
        );
      }
      return (
        <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
          {({ getRootProps, getInputProps }) => (
            <DropZoneWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              {((uploadedMedia && isMediaUploaded) || userProfilePic) && (
              <DropZoneDiv />
              )}
              <Button
                className='update-button'
                cta={((uploadedMedia && isMediaUploaded) || userProfilePic) ? '+ Change Image' : '+ Upload Image'}
                customColor='#10cd8c'
                noHover
                noBorder
                primary
                type='button'
              />
            </DropZoneWrapper>
          )}
        </Dropzone>
      );
    }
    if (mediaIsUploading) {
      return (
        <Spinner saving darkTheme />
      );
    }
  };

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <OuterFolderFormWrapper>
          <InnerFolderFormWrapper>
            <Form className='formik-form'>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text limit-text'>
                    <strong>Program Name</strong>
                    {' '}
                    (Max. 100 characters)
                  </Text>
                  {(formProps.errors.programName && (formProps.touched.programName || page1Errors)) ? (
                    <FieldError className='error-text'>{formProps.errors.programName}</FieldError>
                  ) : null}
                </TitlePlusErrorWrapper>
                <Field
                  className='form-control'
                  id='name-field'
                  maxLength={100}
                  name='programName'
                  placeholder='Calendar Name'
                />
              </FormGroup>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text limit-text'>
                    <strong>Tagline</strong>
                    {' '}
                    (Max. 100 characters)
                  </Text>
                  {formProps.errors.shortDescription && (formProps.touched.shortDescription || page1Errors) ? (
                    <FieldError className='error-text'>{formProps.errors.shortDescription}</FieldError>
                  ) : null}
                </TitlePlusErrorWrapper>
                <Field
                  className='form-control'
                  id='tagline-field'
                  maxLength={100}
                  name='shortDescription'
                  placeholder='Tagline'
                />
              </FormGroup>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text'><strong>Description</strong></Text>
                  {formProps.errors.longDescription && (formProps.touched.longDescription || page1Errors) ? (
                    <FieldError className='error-text'>{formProps.errors.longDescription}</FieldError>
                  ) : null}
                </TitlePlusErrorWrapper>
                <Field
                  component='textarea'
                  className='form-control'
                  name='longDescription'
                  placeholder='Description'
                />
              </FormGroup>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text'><strong>Program URL</strong></Text>
                  {formProps.errors.programUrl && (formProps.touched.programUrl || page1Errors) ? (
                    <FieldError className='error-text'>{formProps.errors.programUrl}</FieldError>
                  ) : null}
                </TitlePlusErrorWrapper>
                <AltFieldWrapper>
                  <Field
                    disabled
                    className='alt-form-control'
                    id='programName-field'
                    name='programUrl'
                    placeholder='Program Name'
                    onFocus={() => {
                      if (!urlHandled) {
                        setUrlHandled(true);
                      }
                    }}
                  />
                </AltFieldWrapper>
                <Text className='url-string' fontSize={14} style={{ marginTop: '5px', overflowWrap: 'anywhere' }}>{`/programs/${currentReseller ? currentReseller.urlSlug : ''}/${formProps.values.programUrl}`}</Text>
              </FormGroup>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text'><strong>Redirect URL</strong></Text>
                  {formProps.errors.redirectUrl && formProps.touched.redirectUrl ? (
                    <FieldError className='error-text'>{formProps.errors.redirectUrl}</FieldError>
                  ) : null}
                </TitlePlusErrorWrapper>
                <Field
                  className='alt-form-control'
                  id='redirectUrl-field'
                  name='redirectUrl'
                  placeholder='Redirect URL'
                />
                <Text className='url-string' fontSize={14} style={{ marginTop: '5px' }}>Optional - subscriber will be redirected here after purchase</Text>
              </FormGroup>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text'><strong>Add Image</strong></Text>
                  {/* <FieldError className='error-text'>{errors.linkUrl}</FieldError> */}
                </TitlePlusErrorWrapper>
                {fileAccepterSwitch()}
                <Text className='url-string' fontSize={14}>Recommended image size: At least 1200 pixels wide for the best quality.</Text>
              </FormGroup>
              <FormGroup>
                <Text className='formgroup-text'><strong>Program Status</strong></Text>
                <Select
                  defaultValue={statusOptions[formProps.values.status]}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={statusOptions}
                  isOptionDisabled={(option) => option.id === 1 && currentUser.organizationSubscriptionStatus !== 'ACTIVE'}
                  onChange={(options) => {
                    formProps.setFieldValue('status', options.id);
                  }}
                />
              </FormGroup>
              {currentUser.organizationSubscriptionStatus !== 'ACTIVE' ? <Text className='url-string' fontSize={14} style={{ marginTop: '5px' }}>A TeamBuildr subscription is required to set program to active</Text> : null}
            </Form>
          </InnerFolderFormWrapper>
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

UpdateProgramSlide1.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  page1Errors: PropTypes.bool.isRequired,
};

export default UpdateProgramSlide1;
