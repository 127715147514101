import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Icon from '../../../../../shared/_TBComponents/Icon';

const TooltipCardWrapper = styled('div')`
  position: fixed;
  top: 50%;
  left: ${({ sidebarWidth }) => `calc(50% + ${sidebarWidth / 2}px)`};
  transform: translate(-50%, -50%); 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 393px;
  height: 369px;
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;

const TooltipOverlay = styled('div')`
  position: fixed;
  top: ${({ headerHeight }) => headerHeight}px;
  left: ${({ sidebarWidth }) => sidebarWidth}px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const MiniCard = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  height: 70px;
  padding: 12px;
  background: ${({ bgColor }) => bgColor || '#FFFFFF'};
  border-radius: 2.5px;
  box-shadow: 0px 1px 1px rgba(8, 5, 10, 0.08), 0px 1px 4px rgba(8, 5, 10, 0.10);
  margin-bottom: 16px;
`;

const MiniIconWrapper = styled('div')`
  width: 37px;
  height: 37px;
  background-color: ${({ iconBgColor }) => iconBgColor || '#E5E5E5'};
  border: ${({ bgColor }) => (bgColor && bgColor !== '#ffffff' ? 'none' : '4px solid rgba(255, 255, 255, 0.6)')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 12px;
`;

const MiniStats = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.0;
`;

const MiniNumber = styled('div')`
  font-size: 20px;
  font-weight: 900;
  color: #333333;
  margin-bottom: 2px;
`;

const MiniText = styled('p')`
  font-size: 8px;
  font-weight: 700;
  color: #666666;
  margin-bottom: 1px;
`;

const Description = styled('p')`
  font-size: 14px;
  color: #555555;
  margin: 8px 0 16px;
`;

const List = styled('ul')`
  padding: 0;
  margin: 0;
  align-self: flex-start;
  list-style: none;
  max-height: 150px;
  overflow-y: auto;
  padding-top: 8px;

  li {
    font-size: 14px;
    color: #333333;
    margin-bottom: 4px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`;

const IconWrapper = styled('div')`
  position: absolute;
  top: 0px;
  right: 8px;
  background: transparent;
  cursor: inherit;

  & svg path {
    fill: ${({ bgColor }) => (bgColor && bgColor !== '#ffffff' ? '#0AA36E' : '#979797')} !important;
  }
`;

const EmptyMessage = styled('p')`
  font-size: 14px;
  color: #777777;
  margin: 0;
  text-align: center;
`;

const TooltipCard = ({
  description, users, isVisible, closeTooltip, sidebarWidth, headerHeight, cardData,
}) => (
  <>
    {isVisible && (
      <TooltipOverlay
        onClick={closeTooltip}
        sidebarWidth={sidebarWidth}
        headerHeight={headerHeight}
      />
    )}
    <TooltipCardWrapper
      headerHeight={headerHeight}
      sidebarWidth={sidebarWidth}
    >
      <MiniCard bgColor={cardData.bgColor}>
        <MiniIconWrapper
          iconBgColor={cardData.iconBgColor}
          bgColor={cardData.bgColor}
        >
          <Icon
            icon={cardData.icon}
            size={cardData.iconSize || 15}
            color={cardData.iconColor || '#FFFFFF'}
          />
        </MiniIconWrapper>
        <MiniStats>
          <MiniNumber>{cardData.number}</MiniNumber>
          <MiniText>{cardData.text}</MiniText>
        </MiniStats>
        <IconWrapper>
          <Icon icon='info2' size={12} />
        </IconWrapper>
      </MiniCard>
      <Description>{description}</Description>
      {Array.isArray(users) && users.length > 0 ? (
        <List>
          {users.map((user) => (
            <li key={user.email}>
              {user.athleteName}
              ,
              {' '}
              {user.email}
            </li>
          ))}
        </List>
      ) : (
        <EmptyMessage>No users found</EmptyMessage>
      )}
    </TooltipCardWrapper>
  </>
);

TooltipCard.propTypes = {
  description: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      athleteName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeTooltip: PropTypes.func.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
  headerHeight: PropTypes.number.isRequired,
  cardData: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    iconBgColor: PropTypes.string,
    bgColor: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string,
  }).isRequired,
};

export default TooltipCard;
