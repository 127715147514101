/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { useSelector, useDispatch } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import IcomoonReact from 'icomoon-react';
import { animated, useTransition, useSpring } from 'react-spring';
import { push } from 'connected-react-router';
import Logger from 'js-logger';
// import { beamerInitialize } from 'react-beamer';
import { openPopupWidget } from 'react-calendly';
import iconSet from '../../images/teambuildr-selection.json';
import DropdownList, { DropdownListContainer, DropdownItem } from '../DropdownList/DropdownList';
import HeaderModals from '../HeaderModals';
import Button from '../Button/Button';
import Text from '../Text/Text';
import useLogin from '../../../modules/login/components/hooks/useLogin';
import FeatureButton from '../../../modules/gymstudio/components/presentational/FeatureButton';

import { setModalOpen } from '../Layout/ducks/layoutActions';

import {
  fetchUnreadNotifications,
} from '../../../modules/conversations/ducks/notificationsActions';
import {
  navigationTracker, navigationTrackerAthlete, subscriptionFlowTracker, subscriptionFlowTrackerDemo,
} from '../../utils/amplitudeHelper';

// Styled Components
const HeaderContainer = styled('nav')`
  align-items: center;
  position: fixed;
  height: 49px;
  width: 100%;
  background-color: ${(props) => props.theme.colorPrimary};
  box-shadow: 1px;
  z-index: 1000;
  display: ${(props) => (props.hideHeader ? 'none' : 'flex')};

  .upgrade {
    color: white;
    &:hover {
      color: white;
      opacity: .8;
    }
  }

  .dropdown-list {
    padding: 0px;
    height: auto;
    margin-left: 0px;
    transition: all 250ms ease-in-out;
  }

  .renew {
    color: white;
    &:hover {
      color: white;
      opacity: .8;
    }
  }

  @media screen and (max-width: 767px) {
    .mobile-dropdown {
      a {
        font-size: 14px;
        padding: 10px 20px;
      }
    }
    
    .mobile-subdropdown {
      a {
        font-size: 12px;
        font-weight: 700;
        padding: 5px 15px;
      }
    }
  }

  .pulse {
    color: white;
    border: 0px;
    box-shadow: 0 0 0 0 rgba(13, 204, 138, 1);
    animation: pulser 3s infinite, gradient 7s ease infinite;
    /* background: linear-gradient(-45deg, #3ebee0, #FC6076); */
    /* background: linear-gradient(-45deg, #3ebee0, #83dcb3); */
    background: linear-gradient(-45deg,#0dcc8a,#0eab8e);
    background-size: 400% 400%;
  };

  .pulse:hover {
    animation: pulser 0s infinite, gradient 7s ease infinite;
    border: 0px;
    opacity: .8;
  }

  @keyframes pulser {
    0% {
    }
    70% {
      box-shadow: 0 0 0 20px rgba(255, 102, 0, 0);
    }
      100% {
      box-shadow: 0 0 0 0 rgba(255, 102, 0, 0);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const NavLink = styled('a')`
  display: flex;
  position: relative;
  height: 49px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;

  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: 23px;
  text-decoration: none;
  color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')};
  cursor: pointer;

  .dropdown-link {
    color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')}
  }
  
  &.nav-links:hover {
    background-color: ${(props) => props.theme.colorSecondary};
    color: ${(props) => (props.theme.isSecondaryDarkTheme ? 'white' : '#444444')};
    
    span {  
      border-color: ${(props) => (props.theme.isSecondaryDarkTheme ? 'white' : '#444444')} transparent transparent transparent;
    }
    svg {
      path {
        fill: ${(props) => (props.theme.isSecondaryDarkTheme ? 'white' : '#444444')}
      }
    }
  }

  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
    object-fit: cover;
    &.img-circle {
      border-radius: 50%;
    }
  }

  &.image-link {
    padding: 9px!important;
  }

  svg {
    path {
      fill: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')};
      transition: all 200ms ease-in-out;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    font-size: 10px;
  }

  @media screen and (max-width: 767px) {
    .dropdown-list {
      width: 100%;
    }
  }

  .notification-dot-envelope {
    width: 15px;
    height: 15px;
    background: #FF6600;
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 7px;
    font-family: 'Nunito Sans';
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 16px;
    padding-left: 1px;
  }
`;

const SubArrow = styled('span')`
  position: absolute;
  height: 0;
  background: transparent;
  border-width: 5px;
  border-style: solid dashed dashed dashed;
  border-color: ${(props) => (
    props.theme.isPrimaryDarkTheme ? '#ffffff transparent transparent transparent' : '#444444 transparent transparent transparent'
  )};
  border-radius: 0;
  bottom: 2px;
`;

const RightNav = styled('div')`
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  right: 0px;
`;

const MainNav = styled('div')`
  display: flex;
  flex-direction: row;
`;

const LeftNav = styled('div')`
`;

const HamburgerIcon = styled('div')`
  width: 22px;
  height: 17px;
`;

const HamburgerLine = styled('div')`
  width: 100%;
  height: 2px;
  background-color: white;
  margin-bottom: 5px;
`;

const BillingNav = styled('div')`
  display: flex;
  align-items: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
  p {
    margin-right: 10px;
  }
`;

// Object styles for transitions
const defaultDropdownStyles = {
  position: 'absolute',
  height: 'auto',
  width: 'auto',
  top: '49px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const mainDropdownStyles = {
  left: '0px',
};

const accountDropdownStyles = {
  right: '0px',
};

const mobileDropdownStyles = {
  position: 'fixed',
  width: '100%',
  left: '0px',
};

const mobileSubDropdownStyles = {
  position: 'relative',
  width: '100%',
  height: '0',
  top: '0px',
  left: '0px',
};

// Main Component
const AppHeader = ({
  data,
  fetchUserAccounts,
  handleLogOut,
  location,
  currentUser,
  hideHeader,
  flags,
}) => {
  const [activeDropdown, setActiveDropdown] = useState('');
  const [activeSubDropdown, setActiveSubDropdown] = useState('');
  const [isLighthouseOpen, setLighthouseOpen] = useState(false);
  // const [activeModal, setActiveModal] = useState('');

  const activeModal = useSelector((state) => state.settings.ui.activeModal);
  const unreadNotificationsNumber = useSelector(
    (state) => state.notifications.data.unreadNotificationsNumber,
  );
  const unreadConversationsNumber = useSelector(
    (state) => state.conversations.data.unreadConversationsNumber,
  );
  // const isModalOpen = useSelector(state => state.settings.isModalOpen);
  const mobileDropdownRef = useRef(null);

  const { handleFetchMe, isLoginLoading } = useLogin();

  const theme = useTheme();

  const dispatch = useDispatch();

  // Dropdown transition animations.
  const workoutDropdownTransition = useTransition(activeDropdown === 'workout', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
    immediate: (key) => key === 'leave', // Immediately remove on leave
  });

  const coachDropdownTransition = useTransition(activeDropdown === 'coach', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
    immediate: (key) => key === 'leave', // Immediately remove on leave
  });

  const accountDropdownTransition = useTransition(activeDropdown === 'account', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
    immediate: (key) => key === 'leave', // Immediately remove on leave
  });

  const mobileDropdownTransition = useTransition(activeDropdown === 'mobile', null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
    immediate: (key) => key === 'leave', // Immediately remove on leave
  });

  const mobileWorkoutDropdownTransition = useSpring({ height: activeSubDropdown === 'mobile-workout' ? 196 : 0 });
  const mobileCoachDropdownTransition = useSpring({ height: activeSubDropdown === 'mobile-coach' ? 224 : 0 });

  // Filter dropdown menu items based on account status.
  const {
    id,
    admin,
    organizationStripe,
    organizationSubscriptionStatus,
    organizationSubscriptionDaysRemaining,
    pic,
  } = data;

  const isAccountTBAdmin = process.env.TB_ADMINS.split(',').includes(`${id}`);
  const isAccountStripe = organizationStripe !== '';
  const isAccountAdmin = admin === 1;

  // const organizationSubscriptionStatus = 'IN_TRIAL';
  // const organizationSubscriptionStatus = 'TRIAL_ENDED';
  // const organizationSubscriptionStatus = 'ACTIVE';
  // const organizationSubscriptionStatus = 'EXPIRED';

  // const organizationSubscriptionDaysRemaining = 45;

  const isInTrial = organizationSubscriptionStatus === 'IN_TRIAL';
  const isTrialExpired = organizationSubscriptionStatus === 'TRIAL_ENDED';
  const isActive = organizationSubscriptionStatus === 'ACTIVE';
  const isExpired = organizationSubscriptionStatus === 'EXPIRED';

  const organizationStripeSubscriptionActive = currentUser
    && currentUser.organizationStripeSubscriptionActive;

  const { canAccessGymStudioHub } = currentUser;

  // Setup support help category based on pathname location.
  const pathname = location && location.replace(/\//g, '');
  let helpCategory = '';

  switch (pathname) {
    case 'feed':
      helpCategory = 'tag:page-feed';
      break;
    case 'calendar':
      helpCategory = 'tag:page-calendar';
      break;
    case 'display':
      helpCategory = 'tag:page-wrv';
      break;
    case 'workouts':
      helpCategory = 'tag:page-entry';
      break;
    case 'maxes':
      helpCategory = 'tag:page-maxes';
      break;
    case 'journal':
      helpCategory = 'tag:page-journal';
      break;
    case 'leaderboard':
      helpCategory = 'tag:page-leaderboard';
      break;
    case 'evaluation':
      helpCategory = 'tag:page-evaluations';
      break;
    case 'manage_users':
      helpCategory = 'tag:page-manageusers';
      break;
    case 'manage_calendars':
      helpCategory = 'tag:page-managecalendars';
      break;
    case 'exercises':
      helpCategory = 'tag:page-exercises';
      break;
    case 'goals':
      helpCategory = 'tag:page-goals';
      break;
    case 'documents':
      helpCategory = 'tag:page-docs';
      break;
    case 'packet':
    case 'printables':
    case 'weightroom-sheet':
      helpCategory = 'tag:page-packet';
      break;
    case 'attendance':
      helpCategory = 'tag:page-attendance';
      break;
    case 'reporting':
      helpCategory = 'tag:page-reporting';
      break;
    case 'messages':
      helpCategory = 'tag:page-messages';
      break;
    case 'dashboard':
      helpCategory = 'tag:page-dashboard';
      break;
    case 'billing':
      helpCategory = 'tag:page-billing';
      break;
    case 'settings':
      helpCategory = 'tag:page-settings';
      break;
    default:
      helpCategory = '';
  }

  const userSettings = data.userSettings !== undefined && data.userSettings;
  const userSkin = data.userSkin !== undefined && data.userSkin;

  // Dropdown Data
  const CoachToolsDropdown = [
    {
      id: 0,
      name: 'Manage Users',
      isVisible: userSettings.manageUsers,
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/manage_users`,
    },
    {
      id: 1,
      name: 'Manage Calendars',
      href: '/calendars',
      isReactLink: true,
    },
    {
      id: 2,
      name: 'Exercises',
      isVisible: userSettings.exercises,
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/exercises`,
    },
    {
      id: 3,
      name: 'Goals',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/goals`,
    },
    {
      id: 4,
      name: 'Documents & Links',
      href: '/documents',
      isReactLink: true,
    },
    {
      id: 5,
      name: 'Packets & Sheets',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/printables`,
    },
    {
      id: 6,
      name: 'Attendance',
      isVisible: userSettings.attendance,
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/attendance`,
    },
    // {
    //   id: 7,
    //   name: 'Reporting',
    //   isVisible: userSettings.reporting,
    //   href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/reporting`,
    // },
    {
      id: 7,
      name: 'Reporting',
      isVisible: userSettings.reporting,
      href: '/reporting',
      isReactLink: true,
    },
    {
      id: 8,
      name: 'Payments',
      isVisible: (currentUser
        && (currentUser.organizationResellerAccountEnabled || currentUser.primaryAdmin > 0)
        && currentUser.userSettings.reseller === 1),
      href: '/reseller',
      isReactLink: true,
    },
  ];

  const WorkoutToolsDropdown = [
    {
      id: 0,
      name: 'Weightroom View',
      href: '/weightroom-view',
      isReactLink: true,
    },
    { id: 1, name: 'Whiteboard', href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/whiteboard` },
    {
      id: 2,
      name: 'Workout Entry',
      href: '/workouts',
      isReactLink: true,
    },
    { id: 3, name: 'Maxes/Pr', href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/maxes` },
    { id: 4, name: 'Journal', href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/journal` },
    {
      id: 5,
      name: 'Leaderboard',
      isVisible: userSettings.leaderboard,
      href: '/leaderboard',
      isReactLink: true,
    },
    {
      id: 6, name: 'Evaluations', href: '/evaluations', isReactLink: true,
    },
  ];

  const MobileCoachNav = [
    {
      id: 0,
      name: 'Feed',
      isVisible: userSettings.feed,
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/feed`,
    },
    { id: 1, name: 'Calendar', href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/calendar` },
    { id: 2, name: 'Workout Tools', href: '#workoutTools' },
    { id: 3, name: 'Coach Tools', href: '#coachTools' },
    {
      id: 4,
      name: 'Messages',
      isVisible: userSettings.messaging,
      isReactLink: true,
      href: '/messaging',
    },
  ];

  const MobileAthleteNav = [
    {
      id: 0,
      name: 'Feed',
      isVisible: userSettings.feed,
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/feed`,
    },
    {
      id: 1, name: 'Workouts', isReactLink: true, href: '/workouts',
    },
    { id: 2, name: 'Maxes/PRS', href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/maxes` },
    { id: 3, name: 'Journal', href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/journal` },
    {
      id: 4,
      name: 'Leaderboard',
      isVisible: userSettings.leaderboard,
      isReactLink: true,
      href: '/leaderboard',
    },
    {
      id: 5, name: 'Docs & Links', isReactLink: true, href: '/documents',
    },
  ];

  const AccountDropdown = [
    {
      id: 0,
      icon: 'key',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/panel`,
      name: 'Admin Panel',
      spacer: true,
    },
    {
      id: 1,
      name: 'Dashboard',
      icon: 'home',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/dashboard`,
    },
    {
      id: 10,
      name: 'Profile',
      icon: 'user',
      href: '/profile',
      isReactLink: true,
    },
    {
      id: 2,
      name: 'Settings',
      icon: 'settings',
      href: '/settings',
      isReactLink: true,
    },
    {
      id: 3,
      name: 'Billing',
      icon: 'dollar',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/billing`,
      isVisible: userSettings.billing,
    },
    {
      id: 4,
      name: 'Subscription',
      icon: 'credit-card',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/settings/subscription`,
    },
    {
      id: 5,
      name: 'Education Center',
      icon: 'headset',
      href: `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/education`,
    },
    // {
    //   id: 6,
    //   customClass: 'beamerTrigger',
    //   name: 'Whats New',
    //   icon: 'star-full',
    //   isVisible: isAccountAdmin,
    //   href: '#whatsNew',
    // },
    {
      id: 7,
      name: 'Support',
      icon: 'question-mark-circle',
      href: '#support',
      modal: true,
    },
    {
      id: 8,
      name: 'Switch Accounts',
      icon: 'swap',
      spacer: true,
      href: '#switchAccounts',
      modal: true,
    },
    {
      id: 9,
      name: 'Log Out',
      icon: 'power',
      isLogOut: true,
      href: '#',
    },
  ];

  // Initiate Lighthouse Support Modal
  useLayoutEffect(() => {
    window.hdlh = {
      widget_key: '1n5kmv92ij0mb7twdi57',
      logo: false,
      primary_color: theme.colorPrimary,
      suggestions: helpCategory !== '' ? [`${helpCategory}`] : null,
      brand: 'Quick Tips',
      color_mode: 'auto',
      disable_contact_button: true,
      disable_authorship: true,
    };

    (function (h, d) { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `${h}?t=${new Date().getTime()}`; d.head.appendChild(s); }('https://lighthouse.helpdocs.io/load', document));
  }, [location]);

  // Fetch Unread Messages
  useEffect(() => {
    dispatch(fetchUnreadNotifications());
    if (!isLoginLoading) {
      handleFetchMe();
    }
  }, [location]);

  // Beamer Initialize
  // useEffect(() => {
  //   beamerInitialize('EAvbPFbN21276');
  // }, []);

  // Click handle functions
  const handleSupportClick = (e) => {
    e.preventDefault();
    navigationTracker('Support Dropdown Menu');

    if (!isLighthouseOpen) {
      setLighthouseOpen(true);
      window.Lighthouse.show();
    } else {
      setLighthouseOpen(false);
      window.Lighthouse.hide();
    }
  };

  const handleActiveModal = (modal, e) => {
    e.preventDefault();
    Logger.debug('Opening a modal');
    dispatch(setModalOpen(modal));
    // setModalOpen('');
    if (modal === 'Switch Accounts') {
      fetchUserAccounts();
    }
  };

  const handleActiveDropdown = (dropdown, e) => {
    e.preventDefault();
    setActiveDropdown(dropdown);
  };

  const closeModal = () => {
    dispatch(setModalOpen(''));
  };

  const handleMobileClick = (e) => {
    e.preventDefault();
    activeDropdown === 'mobile'
      ? setActiveDropdown('')
      : setActiveDropdown('mobile');
  };

  const handleSubDropdownClick = (dropdown, e) => {
    e.preventDefault();
    setActiveSubDropdown(activeSubDropdown === dropdown ? '' : dropdown, e);
  };

  const handleLink = (href) => {
    window.location = href;
  };

  const handleReactLink = (path, e) => {
    e.preventDefault();
    dispatch(push(path));
  };

  const handleLogOutClick = (e) => {
    e.preventDefault();
    handleLogOut();
  };

  const handleDropdownLink = (link) => {
    window.location.href = link.href;
    return null;
  };

  // Dropdown Data Filtering Based on visibility and access.

  const filteredTBAdminAccountDropdown = AccountDropdown.filter((link) => (
    (!isAccountTBAdmin ? link.id !== 0 : link.id < 20)
  ));
  const filteredAdminAccountDropdown = filteredTBAdminAccountDropdown.filter((link) => (
    (!isAccountAdmin ? (link.id !== 3
      && link.id !== 5 && link.id !== 7) : (link.id !== 4
        && link.id !== 10))
  ));
  const filteredStripeAccountDropdown = filteredAdminAccountDropdown.filter((link) => (
    (!isAccountStripe ? link.id !== 4 : link.id < 20)
  ));

  return (
    <HeaderContainer hideHeader={hideHeader}>
      <LeftNav>
        <NavLink className='image-link'>
          <img
            src={userSkin.logo}
            alt=''
          />
        </NavLink>
      </LeftNav>

      <MainNav className='d-none d-md-flex'>
        {userSettings.feed === 1 && (
          <NavLink
            href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/feed`}
            className='nav-links'
            onClick={() => navigationTracker('Feed')}
          >
            Feed
          </NavLink>
        )}
        {isAccountAdmin ? (
          // Show Links for Admin Users
          <>
            <NavLink href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/calendar`} className='nav-links'>
              Calendar
            </NavLink>
            <NavLink
              href='#'
              className='nav-links'
              onMouseEnter={(e) => handleActiveDropdown('workout', e)}
              onMouseLeave={(e) => handleActiveDropdown('', e)}
              onClick={(e) => { handleActiveDropdown(activeDropdown !== 'workout' ? 'workout' : '', e); navigationTracker('Calendar'); }}
            >
              Workout Tools
              <SubArrow />

              {/* Workout Dropdown Menu */}
              {workoutDropdownTransition.map(({ item, props }) => item && (
                <animated.div style={{ ...defaultDropdownStyles, ...mainDropdownStyles, ...props }}>
                  <DropdownList
                    componentClass='dropdown-list'
                    customBackground={theme.colorPrimary}
                    data={WorkoutToolsDropdown}
                    handleReactLink={handleReactLink}
                    handleLink={handleLink}
                    light={false}
                    small
                    smallPadding
                  />
                </animated.div>
              ))}
            </NavLink>
            <NavLink
              href='#'
              className='nav-links'
              onMouseEnter={(e) => handleActiveDropdown('coach', e)}
              onMouseLeave={(e) => handleActiveDropdown('', e)}
              onClick={(e) => handleActiveDropdown(activeDropdown !== 'coach' ? 'coach' : '', e)}
            >
              Coach Tools
              <SubArrow />

              {/* Coach Dropdown Menu */}
              {coachDropdownTransition.map(({ item, props }) => item && (
                <animated.div style={{ ...defaultDropdownStyles, ...mainDropdownStyles, ...props }}>
                  <DropdownList
                    componentClass='dropdown-list'
                    customBackground={theme.colorPrimary}
                    data={CoachToolsDropdown}
                    handleLink={handleLink}
                    small
                    smallPadding
                    handleActive={handleDropdownLink}
                    handleReactLink={handleReactLink}
                  />
                </animated.div>
              ))}
            </NavLink>
            {userSettings.reporting ? (
              <NavLink
                onClick={(e) => { handleReactLink('/sports-science/wearables-dashboard', e); navigationTracker('Sport Science Hub'); }}
                href='/sports-science/wearables-dashboard'
                className='nav-links'
              >
                Sport Science Hub
              </NavLink>
            ) : null}
          </>
        ) : (
          // Show links for athletes
          <>
            <NavLink
              href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/workouts`}
              onClick={(e) => { e.preventDefault(); dispatch(push('/login')); navigationTrackerAthlete('Workouts'); }}
              className='nav-links'
            >
              Workouts
            </NavLink>
            <NavLink href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/maxes`} onClick={() => navigationTrackerAthlete('Maxes/PR')} className='nav-links'>
              Maxes/PR
            </NavLink>
            <NavLink href={`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/journal`} onClick={() => navigationTrackerAthlete('Journal')} className='nav-links'>
              Journal
            </NavLink>
            {userSettings.leaderboard === 1 && (
              <NavLink onClick={(e) => { e?.preventDefault(); handleReactLink('/leaderboard', e); navigationTrackerAthlete('Leaderboard'); }} href='/leaderboard' className='nav-links'>
                Leaderboard
              </NavLink>
            )}
            <NavLink href='/documents' onClick={(e) => { e.preventDefault(); handleReactLink('/documents', e); navigationTrackerAthlete('Docs & Links'); }} className='nav-links'>
              Docs & Links
            </NavLink>
          </>
        )}
      </MainNav>

      <RightNav>
        {/* Billing Nav */}
        {isAccountAdmin && (
          <>
            <BillingNav>
              {(!organizationStripeSubscriptionActive && (isInTrial || isTrialExpired)) && (
                <>
                  {!isTrialExpired && (
                    <Text
                      color={theme.isPrimaryDarkTheme ? 'white' : '#444444'}
                      fontSize={theme.textFontSizes[0]}
                    >
                      {organizationSubscriptionDaysRemaining}
                      &nbsp;days left
                    </Text>
                  )}

                  <Button
                    className='upgrade'
                    cta='Upgrade'
                    customColor='#ff6600'
                    onClick={() => {
                      window.location.href = `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/billing`;
                      // Track upgrade click for amplitude
                      subscriptionFlowTracker('Billing Nav Upgrade Clicked', 'none', 'none');
                    }}
                    noBorder
                    primary
                    rounded
                    noHover
                  />
                  <Button
                    className='d-none d-sm-flex pulse'
                    cta='Schedule Demo'
                    customColor='transparent'
                    onClick={() => {
                      // Track demo click for amplitude
                      subscriptionFlowTrackerDemo('Billing Nav', { title: 'none', core: 'none', addOn: 'none' });
                      openPopupWidget({
                        url: 'https://calendly.com/teambuildr',
                      });
                    }}
                    primary
                    rounded
                  />
                </>
              )}
              {((isActive && organizationSubscriptionDaysRemaining <= 30
                && !organizationStripeSubscriptionActive)
                || (isExpired)) && (
                <>
                  {!isExpired && (
                    <Text
                      color={theme.isPrimaryDarkTheme ? 'white' : '#4444444'}
                      fontSize={theme.textFontSizes[0]}
                    >
                      {organizationSubscriptionDaysRemaining}
                      &nbsp;days left
                    </Text>
                  )}
                  <Button
                    className='renew'
                    cta='Renew'
                    customColor='#c0392b'
                    onClick={() => {
                      window.location.href = `${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/billing`;
                      // Track renew click for amplitude
                      subscriptionFlowTracker('Subscription Renewed', 'none', 'none');
                    }}
                    noBorder
                    primary
                    rounded
                    noHover
                  />
                  <Button
                    className='d-none d-sm-flex pulse'
                    cta='Schedule Demo'
                    customColor='transparent'
                    onClick={() => {
                      // Track demo click for amplitude
                      subscriptionFlowTrackerDemo('Billing Nav', { title: 'none', core: 'none', addOn: 'none' });
                      openPopupWidget({
                        url: 'https://calendly.com/teambuildr',
                      });
                    }}
                    primary
                    rounded
                  />
                </>
              )}
            </BillingNav>
            {canAccessGymStudioHub && flags.gymStudioIntegration && (
            <FeatureButton />
            )}
            <NavLink
              id='help-link'
              onClick={handleSupportClick}
              href='#'
              className='nav-links d-none d-md-flex'
            >
              <IcomoonReact
                iconSet={iconSet}
                // color={isPrimaryDarkTheme ? '#444444' : '#FFFFFF'}
                size={18}
                icon='lightning-bolt'
                className='align-self-end'
              />
            </NavLink>
          </>
        )}
        <NavLink href='/messaging' onClick={(e) => { handleReactLink('/messaging', e); navigationTracker('Messaging'); }} className='nav-links d-none d-md-flex'>
          <IcomoonReact
            iconSet={iconSet}
            // color={isPrimaryDarkTheme ? '#444444' : '#FFFFFF'}
            size={18}
            icon={userSettings.messaging === 1 ? 'envelope-closed' : 'alert-on'}
            className='align-self-end'
          />
          {unreadConversationsNumber
              || unreadNotificationsNumber
            ? (
              <div className='notification-dot-envelope'>
                {unreadConversationsNumber
                    + unreadNotificationsNumber < 10
                  ? unreadConversationsNumber
                    + unreadNotificationsNumber
                  : '9+'}
              </div>
            ) : null}
        </NavLink>

        {/* Mobile Navigation */}
        <NavLink
          href='#'
          className='d-flex d-md-none'
          onClick={(e) => handleMobileClick(e)}
        >
          <HamburgerIcon>
            <HamburgerLine />
            <HamburgerLine />
            <HamburgerLine />
          </HamburgerIcon>
        </NavLink>

        {/* Mobile Dropdown Menu */}
        {mobileDropdownTransition.map(({ item, props }) => item && (
          <animated.div
            ref={mobileDropdownRef}
            style={{ ...defaultDropdownStyles, ...mobileDropdownStyles, ...props }}
          >
            <DropdownListContainer
              className='dropdown-list mobile-dropdown'
              customBackground={theme.colorPrimary}
            >
              {(isAccountAdmin ? MobileCoachNav : MobileAthleteNav).map((dropItem) => (
                <>
                  {isAccountAdmin ? (
                    <DropdownItem
                      small
                      smallPadding
                      href={dropItem.href}
                      onClick={(e) => (
                        (dropItem.id === 2 || dropItem.id === 3)
                          && (handleSubDropdownClick(dropItem.id === 2 ? 'mobile-workout' : 'mobile-coach', e))
                      )}
                    >
                      {dropItem.name}
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      small
                      smallPadding
                      href={dropItem.href}
                    >
                      {dropItem.name}
                    </DropdownItem>
                  )}

                  {/* Mobile Sub-Dropdown */}
                  {(dropItem.id === 2 || dropItem.id === 3) && (
                    <animated.div
                      ref={mobileDropdownRef}
                      style={
                        dropItem.id === 2
                          ? {
                            ...defaultDropdownStyles,
                            ...mobileSubDropdownStyles,
                            ...mobileWorkoutDropdownTransition,
                          }
                          : {
                            ...defaultDropdownStyles,
                            ...mobileSubDropdownStyles,
                            ...mobileCoachDropdownTransition,
                          }
                      }
                    >
                      <DropdownList
                        componentClass='dropdown-list mobile-subdropdown'
                        customBackground={theme.colors.background[0]}
                        customHover='#000000'
                        data={dropItem.id === 2 ? WorkoutToolsDropdown : CoachToolsDropdown}
                        handleActiveModal={handleActiveModal}
                        light
                        extraSmallPadding
                      />
                    </animated.div>
                  )}
                </>
              ))}
            </DropdownListContainer>
          </animated.div>
        ))}

        <NavLink
          href='#'
          className='image-link'
          onMouseEnter={() => setActiveDropdown('account')}
          onMouseLeave={() => setActiveDropdown('')}
          onClick={() => (setActiveDropdown(activeDropdown !== 'account' ? 'account' : ''))}
        >
          <img
            src={pic}
            alt=''
            className='img-circle'
          />

          {/* Account Dropdown Menu */}
          {accountDropdownTransition.map(({ item, props }) => item && (
            <animated.div style={{ ...defaultDropdownStyles, ...accountDropdownStyles, ...props }}>
              <DropdownList
                componentClass='dropdown-list'
                customBackground={theme.colors.background[0]}
                customHover='#000000'
                customHoverTextColor='#ffffff'
                data={filteredStripeAccountDropdown}
                handleActiveModal={handleActiveModal}
                handleLogOut={handleLogOutClick}
                light
                icons
                small
                extraSmallPadding
              />
            </animated.div>
          ))}

        </NavLink>
      </RightNav>

      <HeaderModals
        activeModal={activeModal}
        closeModal={closeModal}
        isModalOpen={activeModal !== ''}
        path={location}
        organizationId={data.organizationId}
      />
    </HeaderContainer>
  );
};

AppHeader.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  fetchUserAccounts: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
};

AppHeader.defaultProps = {
  hideHeader: undefined,
};

export default withLDConsumer()(AppHeader);
