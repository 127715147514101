import React from 'react';
import PropTypes from 'prop-types';
import Shimmer from 'react-shimmer-effect';
import styled from '@emotion/styled';

import SubText from '../../../../../shared/components/SubText/SubText';

const CardContainer = styled('div')`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 
    0px 1px 1px rgba(8, 5, 10, 0.08),
    0px 1px 4px rgba(8, 5, 10, 0.10); 
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ScheduleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 1.0;
  width: 100%;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const ScheduleItem = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden; 
  border: 1px solid #CFCFCF;
  border-radius: 4px;
  padding: 12px 8px;
  margin-bottom: 8px;
  width: 100%;
  min-height: 84px;
  min-width: 200px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 8px;
  }
`;

const ShimmerScheduleItem = styled('div')`
  width: 100%;
  height: 85px;
  border-radius: 8px;
  margin-bottom: 8px;
`;

const TopColorBar = styled('div')`
  display: block;
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 4px;
  background-color: ${({ itemType }) => (itemType === 'appointment' ? '#F6C300' : '#F5874E')};
  border: 1px solid ${({ itemType }) => (itemType === 'appointment' ? '#F6C300' : '#F5874E')};
  border-bottom-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const DataTitle = styled('div')`
  font-size: 14px;
  font-weight: 700;
  color: #444444;
  margin-bottom: 4px;
  font-family: 'Nunito Sans', sans-serif;
`;

const FlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const DataNumber = styled('div')`
  font-size: 10px;
  font-weight: 400;
  color: #616161;
  font-family: 'Nunito Sans', sans-serif;
`;

const DataSubtitle = styled('div')`
  font-size: 12px;
  font-weight: 700;
  color: #616161;
  margin: 8px 0 4px 0;
  font-family: 'Nunito Sans', sans-serif;

  @media (max-width: 1024px) {
    margin: 4px 0;
  }
`;

const DataDetails = styled('div')`
  font-size: 10px;
  font-weight: 700;
  color: #9E9E9E;
  font-family: 'Nunito Sans', sans-serif;
`;

const ScheduleCard = ({
  scheduleItems, isStudioConnected, loadingQuickStats, initialLoading,
}) => {
  const renderContent = () => {
    if (!isStudioConnected || initialLoading || loadingQuickStats) {
      return (
        <Shimmer>
          {['shimmer-1', 'shimmer-2', 'shimmer-3', 'shimmer-4'].map((key) => (
            <ShimmerScheduleItem key={key} />
          ))}
        </Shimmer>
      );
    }

    if (scheduleItems.length === 0) {
      return (
        <SubText fontSize='12px' fontWeight='500' style={{ margin: '24px' }}>
          You do not have any items on your schedule today!
        </SubText>
      );
    }

    // Render schedule items
    return scheduleItems.map((item) => (
      <ScheduleItem key={item.id}>
        <TopColorBar itemType={item.itemType} />
        <FlexContainer>
          <TitleContainer>
            <DataTitle>{item.name}</DataTitle>
            <DataNumber>{item.time}</DataNumber>
          </TitleContainer>
          {item.itemType === 'class' && (
            <DataNumber>
              {item.spots}
              {' '}
              spots
            </DataNumber>
          )}
        </FlexContainer>
        {item.itemType === 'class' && (
          <>
            <DataSubtitle>{item.instructor}</DataSubtitle>
            <DataDetails>{item.location}</DataDetails>
          </>
        )}
        {item.itemType === 'appointment' && (
          <DataDetails>{item.category}</DataDetails>
        )}
      </ScheduleItem>
    ));
  };

  return (
    <CardContainer>
      <ScheduleContainer>
        <HeaderContainer>
          <SubText fontSize='14px' fontWeight='800' style={{ margin: '24px 0' }}>
            TODAY&apos;S SCHEDULE
          </SubText>
        </HeaderContainer>
        {renderContent()}
      </ScheduleContainer>
    </CardContainer>
  );
};

ScheduleCard.propTypes = {
  scheduleItems: PropTypes.arrayOf(
    PropTypes.shape({
      borderColor: PropTypes.string,
      name: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      instructor: PropTypes.string,
      location: PropTypes.string,
      spots: PropTypes.string,
      category: PropTypes.string,
      itemType: PropTypes.oneOf(['class', 'appointment']).isRequired,
    }),
  ).isRequired,
  isStudioConnected: PropTypes.bool,
  loadingQuickStats: PropTypes.bool,
  initialLoading: PropTypes.bool,
};

ScheduleCard.defaultProps = {
  isStudioConnected: false,
  loadingQuickStats: false,
  initialLoading: false,
};

export default ScheduleCard;
