import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';

import GymStudioButton from '../../../../../shared/_TBComponents/Button';
import Icon from '../../../../../shared/_TBComponents/Icon';

const CardContainer = styled('div')`
  background: #ffffff;
  border-radius: 4px;
  flex: 1;
  text-align: center;
  box-shadow: 
    0px 1px 1px rgba(8, 5, 10, 0.08),
    0px 1px 4px rgba(8, 5, 10, 0.10); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 86px;
`;

const Title = styled('h3')`
  font-size: 16px;
  font-weight: 800;
  color: #444444;
  padding: 24px;
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;
`;

const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #9E9E9E;
`;

const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  .Shimmer-shimmer-0-1-1 {
    background-repeat: repeat !important;
  }
`;

const IconWrapper = styled('div')`
  background: linear-gradient(180deg, #EEEDF0 0%, #D4D3D8 100%);
  border-radius: 50%;
  height: 140px;
  width: 140px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShimmerContent = styled('div')`
  width: ${(props) => props.width || '470px'};
  height: 24px;
  border-radius: 8px;
`;

const ShimmerIcon = styled('div')`
  width: ${(props) => props.width || '200px'};
  border-radius: 50%;
  height: 140px;
  width: 140px;
  overflow: hidden;
`;

const UserActionsCard = ({
  title, icon, iconColor, iconSize, buttonProps, isLoading,
}) => (
  <>
    <CardContainer>
      <TitleContainer>
        {isLoading ? (
          <Title style={{ marginBottom: '0px' }}>
            <Shimmer>
              <ShimmerContent />
            </Shimmer>
          </Title>
        ) : (
          <Title style={{ marginBottom: '0px' }}>{title}</Title>
        )}
      </TitleContainer>
      <Divider />
      <FlexContainer>
        {isLoading
          ? (
            <Shimmer>
              <ShimmerIcon />
            </Shimmer>
          ) : (
            <FlexContainer>
              <IconWrapper>
                <Icon icon={icon} color={iconColor} size={iconSize} />
              </IconWrapper>
              <GymStudioButton {...buttonProps} />
            </FlexContainer>
          )}
      </FlexContainer>
    </CardContainer>
  </>
);

UserActionsCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  buttonProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    txtColor: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    borderColor: PropTypes.string,
    customBorderRadius: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    onClick: PropTypes.func,
  }).isRequired,
  isLoading: PropTypes.bool,
};

GymStudioButton.propTypes = {
  styledTextProps: PropTypes.shape({
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    letterSpacing: PropTypes.string,
  }),
};

UserActionsCard.defaultProps = {
  iconColor: '#333333',
  iconSize: 50,
  isLoading: false,
};

export default UserActionsCard;
