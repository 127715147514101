import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import Shimmer from 'react-shimmer-effect';
import { useTheme } from 'emotion-theming';
import moment from 'moment';

import {
  fetchConnectionData, updateMatchedUsers, setActiveGymStudioModal,
} from '../ducks/gymStudioActions';
import ModalHandler from '../components/presentational/ModalHandler';
import MarketingPage from './components/presentational/MarketingPage';
import SettingsDropdown from './components/presentational/SettingsDropdown';
import UserStatsCard from './components/presentational/UserStatsCard';
import UserActionsCard from './components/presentational/UserActionsCard';
import Button from '../../../shared/components/Button/Button';
import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Spinner from '../../../shared/components/Spinner/Spinner';
import Text from '../../../shared/components/Text/Text';
import GymStudioButton from '../../../shared/_TBComponents/Button';

const MainContainer = styled('main')`
  flex: 10;
  position: relative;
  max-width: 1450px;
  margin-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 100%;
    background: linear-gradient(to right, rgba(128, 128, 128, 0.075), transparent);
    z-index: 2;
    pointer-events: none;
  }
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  height: 104px;
  background-color: #ffffff;
  padding: 48px ${(props) => (props.connected ? '32px' : '48px')} 16px ${(props) => (props.connected ? '32px' : '48px')};
  align-items: center;
  justify-content: space-between;

  ${(props) => !props.connected && `
    @media only screen and (max-width: 1350px) {
      height: 148px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 55px 24px 16px 24px;
    }
  `}
`;

const IconButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const BlackIconButton = styled(Button)`
  & svg path {
    fill: black !important;
  }
`;

const ButtonWrapper = styled(animated.div)`
  transform: scale(1.6);
`;

const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.connected ? '0px' : '32px 48px')};
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 1350px) {
    padding: ${(props) => (props.connected ? '0px' : '24px')};
  }
`;

const StatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  padding: 40px;
`;

const StatsBannerContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const KeyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-left: 2px solid #B1B1B1;
  padding-left: 16px;
  gap: 2px;
  flex: 1;

  @media (max-width: 767px) {
    border-left: 1px solid #B1B1B1;
    padding-left: 8px;
  }
`;

const ShimmerValue = styled.div`
  width: ${(props) => props.width || '200px'};
  height: 24px;
  margin-left: 8px;
  border-radius: 8px;
`;

const FlexContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const UsersCardContainer = styled('div')`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: ${(props) => (props.cardCount === 4 ? 'nowrap' : 'wrap')};

  @media (max-width: 1440px) {
    flex-wrap: ${(props) => (props.cardCount === 4 ? 'wrap' : 'wrap')};

    & > * {
      max-width: 100%;
      min-width: ${(props) => (props.cardCount === 4 && props.isSideBarExpanded ? '400px' : '500px')};
    }
  }

  @media (max-width: 1280px) {
    & > * {
      max-width: 100%;
      min-width: ${(props) => (props.cardCount === 4 && props.isSideBarExpanded ? '300px' : '400px')};
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const ActionsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  padding: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const GymOverviewDashboard = ({ currentTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [isContentReady, setIsContentReady] = useState(false);
  const [isMatchedUsersUpdated, setIsMatchedUsersUpdated] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const {
    connectionData, users, showNewUsersCard, isStudioConnected,
  } = useSelector((state) => state.gymStudio.data);
  const {
    loadingConnectionData,
    isSideBarExpanded,
    updatingMatchedUsers,
  } = useSelector((state) => state.gymStudio.ui);

  const sidebarWidth = isSideBarExpanded ? 300 : 96;
  const headerHeight = 49;

  const formattedDate = connectionData?.updatedAt
    ? moment(connectionData?.updatedAt).format('MMMM D, YYYY')
    : moment(connectionData?.connectedAt).format('MMMM D, YYYY');

  const dateLabel = connectionData?.updatedAt ? 'Updated connection:' : 'Connected on:';

  const maskedApiKey = connectionData?.apiKey
    ? `XXXXXXXXX-${connectionData.apiKey.slice(-4)}`
    : '';

  useEffect(() => {
    if (currentUser?.accountCode) {
      setIsContentReady(false);
      dispatch(fetchConnectionData(currentUser.accountCode)).finally(() => {
        setIsContentReady(true);
      });
    }
  }, [currentUser, dispatch]);

  const handleUpdateMatches = () => {
    if (currentUser?.accountCode) {
      dispatch(updateMatchedUsers(currentUser.accountCode));
      setIsMatchedUsersUpdated(true);
    }
  };

  const openInfoModal = () => {
    dispatch(setActiveGymStudioModal('info'));
  };

  const openExportUsersModal = () => {
    dispatch(setActiveGymStudioModal('export-users'));
  };

  const toggleSettingsDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const rotateProps = useSpring({
    to: {
      transform: isDropdownOpen ? 'rotate(360deg) scale(1.6)' : 'rotate(0deg) scale(1.6)',
    },
    from: { transform: 'rotate(0deg) scale(1.6)' },
    config: { duration: 300 },
  });

  const toggleUserTooltip = (cardId) => {
    setActiveTooltip((prev) => {
      const newActiveTooltip = prev === cardId ? null : cardId;
      return newActiveTooltip;
    });
  };

  const renderContent = () => {
    if (!isContentReady) {
      return <Spinner />;
    }

    if (!isStudioConnected) {
      return (
        <>
          <MarketingPage />
          <ModalHandler
            currentTab={currentTab}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </>
      );
    }

    const visibleCards = [
      isMatchedUsersUpdated && showNewUsersCard,
      (users?.existingMatchedUsers?.length || 0) > 0,
      ((users?.missingGsUsers?.length || 0) + (users?.missingTbUsers?.length || 0)) > 0,
      (users?.suspendedUsers?.length || 0) > 0,
    ].filter(Boolean).length;

    return (
      <>
        <MainContainer>
          <DashboardHeader connected>
            <HeaderText
              fontSize='24px'
              fontWeight='900'
              letterSpacing='normal'
              style={{ marginRight: '20px' }}
            >
              Gym Overview
            </HeaderText>
            <IconButtonContainer>
              <ButtonWrapper style={rotateProps}>
                <BlackIconButton
                  icon='settings'
                  iconOnly
                  onClick={toggleSettingsDropdown}
                />
              </ButtonWrapper>
              <SettingsDropdown
                isVisible={isDropdownOpen}
                closeDropdown={() => setIsDropdownOpen(false)}
                sidebarWidth={sidebarWidth}
                headerHeight={headerHeight}
              />
              <ButtonWrapper>
                <Button
                  icon='info2'
                  iconOnly
                  onClick={openInfoModal}
                />
              </ButtonWrapper>
            </IconButtonContainer>
          </DashboardHeader>
          <Container connected>
            <StatsContainer>
              <StatsBannerContainer>
                <KeyContainer>
                  <FlexContainer>
                    <HeaderText fontSize='20px' fontWeight='800'>API Key:</HeaderText>
                    {loadingConnectionData || updatingMatchedUsers ? (
                      <Shimmer>
                        <ShimmerValue />
                      </Shimmer>
                    ) : (
                      <Text fontSize='14px' marginLeft='8px'>{maskedApiKey}</Text>
                    )}
                  </FlexContainer>
                  <FlexContainer>
                    <HeaderText fontSize='20px' fontWeight='800'>
                      {dateLabel}
                    </HeaderText>
                    {loadingConnectionData || updatingMatchedUsers ? (
                      <Shimmer>
                        <ShimmerValue />
                      </Shimmer>
                    ) : (
                      <Text fontSize='14px' marginLeft='8px'>{formattedDate}</Text>
                    )}
                  </FlexContainer>
                </KeyContainer>
                <GymStudioButton
                  name='Update matches'
                  bgColor={theme.colors.gymStudio}
                  txtColor='#FFFFFF'
                  fontSize='14px'
                  styledTextProps={{ fontWeight: '700', letterSpacing: '0.5px' }}
                  borderColor={theme.colors.gymStudio}
                  customBorderRadius='4px'
                  onClick={handleUpdateMatches}
                />
              </StatsBannerContainer>
              <UsersCardContainer cardCount={visibleCards} isSideBarExpanded={isSideBarExpanded}>
                {isMatchedUsersUpdated && showNewUsersCard && (
                <UserStatsCard
                  icon='gs-group'
                  iconBgColor='#0AA36E'
                  bgColor='#CFF5E8'
                  number={users?.newMatchedUsers?.length}
                  text='NEW users added'
                  isLoading={loadingConnectionData || updatingMatchedUsers}
                  isVisible={activeTooltip === 'newUsers'}
                  toggleTooltip={() => toggleUserTooltip('newUsers')}
                  tooltipData={{
                    description: 'New athletes added after updating matches:',
                    users: users?.newMatchedUsers || [],
                  }}
                  sidebarWidth={sidebarWidth}
                  headerHeight={headerHeight}
                />
                )}
                <UserStatsCard
                  icon='gs-group'
                  iconBgColor='#0AA36E'
                  number={users?.existingMatchedUsers?.length}
                  text='Matching users'
                  isLoading={loadingConnectionData || updatingMatchedUsers}
                  isVisible={activeTooltip === 'matchingUsers'}
                  toggleTooltip={() => toggleUserTooltip('matchingUsers')}
                  tooltipData={{
                    description: 'Successfully connected athletes found in both your GymStudio and TeamBuildr accounts:',
                    users: users?.existingMatchedUsers || [],
                  }}
                  sidebarWidth={sidebarWidth}
                  headerHeight={headerHeight}
                />
                <UserStatsCard
                  icon='gs-group'
                  iconBgColor='#CC0404'
                  number={(
                    users?.missingGsUsers?.length || 0) + (users?.missingTbUsers?.length || 0
                  )}
                  text='Unmatched users'
                  isLoading={loadingConnectionData || updatingMatchedUsers}
                  isVisible={activeTooltip === 'unmatchedUsers'}
                  toggleTooltip={() => toggleUserTooltip('unmatchedUsers')}
                  tooltipData={{
                    description: 'Unsuccessful athletes found when integrating GymStudio and TeamBuildr:',
                    users: [...(users?.missingGsUsers || []), ...(users?.missingTbUsers || [])],
                  }}
                  sidebarWidth={sidebarWidth}
                  headerHeight={headerHeight}
                />
                <UserStatsCard
                  icon='gs-credit-card'
                  iconBgColor='#CC0404'
                  number={users?.suspendedUsers?.length}
                  text='Matched & suspended users'
                  isLoading={loadingConnectionData || updatingMatchedUsers}
                  isVisible={activeTooltip === 'suspendedUsers'}
                  toggleTooltip={() => toggleUserTooltip('suspendedUsers')}
                  tooltipData={{
                    description: 'Matched athletes who have failed payments and will not have access to workouts:',
                    users: users?.suspendedUsers || [],
                  }}
                  sidebarWidth={sidebarWidth}
                  headerHeight={headerHeight}
                />
              </UsersCardContainer>
            </StatsContainer>
            <ActionsContainer>
              <UserActionsCard
                title='Add TeamBuildr athletes to GymStudio'
                icon='gs-export'
                iconColor='#333333'
                iconSize={52}
                buttonProps={{
                  name: 'Export user list',
                  bgColor: theme.colors.gymStudio,
                  txtColor: '#FFFFFF',
                  fontSize: '14px',
                  styledTextProps: { fontWeight: '700', letterSpacing: '0' },
                  borderColor: theme.colors.gymStudio,
                  customBorderRadius: '4px',
                  onClick: openExportUsersModal,
                }}
                isLoading={loadingConnectionData || updatingMatchedUsers}
              />
              <UserActionsCard
                title='View studio details'
                icon='gymstudio'
                iconColor='#06283D'
                iconSize={92}
                buttonProps={{
                  name: 'View my studio',
                  bgColor: '#FFFFFF',
                  txtColor: '#333333',
                  fontSize: '14px',
                  styledTextProps: { fontWeight: '700', letterSpacing: '0' },
                  borderColor: '#333333',
                  customBorderRadius: '4px',
                  icon: 'gs-house',
                  iconSize: 12,
                  onClick: () => window.open(connectionData?.studioAdminUrl, '_blank'),
                }}
                isLoading={loadingConnectionData || updatingMatchedUsers}
              />
            </ActionsContainer>
          </Container>
        </MainContainer>
        <ModalHandler
          currentTab={currentTab}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      </>
    );
  };

  return <>{renderContent()}</>;
};

GymOverviewDashboard.propTypes = {
  currentTab: PropTypes.string.isRequired,
};

export default GymOverviewDashboard;
