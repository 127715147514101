import React, { useState, useEffect } from 'react';
import {
  Route, Switch, Redirect, useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import ResizeObserver from 'react-resize-observer';

import GymOverviewDashboard from './gym-overview-dashboard/GymOverviewDashboard';
import QuickStatisticsDashboard from './quick-statistics-dashboard/QuickStatisticsDashboard';
import Layout from '../../shared/components/Layout/Layout';
import SideBar from './components/presentational/SideBar';

import { BoxShadow } from '../../shared/GlobalStyles';
import { setSideBarOpen } from './ducks/gymStudioActions';

const FlexContainer = styled('div')`
  display: flex;
  height: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  height: calc(100% - 49px);
  flex: 1;
  margin-top: 49px;
  transition: all 0.5s ease;
  @media only screen and (max-width : 768px) {
    height: calc(100%);
  }
  background: #F5F5F5;
  overflow-y: scroll;
  @media screen and (max-width: 768px) {
    width: ${(props) => (!props.isSideBarActive ? '100%' : '0%')} ;
  }
`;

const SideBarReturnButton = styled('div')`
  width: 140px;
  height: 35px;
  background: white;
  position: absolute;
  bottom: 20px;
  display: none;
  border-radius: 50px;
  @media screen and (max-width: 714px) {
    display: flex;
  }
  cursor: pointer;
  box-shadow: ${BoxShadow};
  font-size: 10px;
  font-family: 'Nunito Sans';
  align-items: center;
  justify-content: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const ModuleText = styled('div')`
  color: ${(props) => props.theme.colors.gymStudio};
  font-weight: bold;
`;

const GymStudio = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isSideBarOpen = useSelector((state) => state.gymStudio.ui.isSideBarOpen);
  const selectedTab = useSelector((state) => state.gymStudio.ui.selectedTab);

  const [isSideBarActive, setIsSideBarActive] = useState(true);

  useEffect(() => {
    dispatch(setSideBarOpen(isSideBarActive));
  }, [isSideBarActive]);

  useEffect(() => {
    if (selectedTab === 'quick-stats') {
      history.push(`${match.path}/quick-statistics`);
    } else if (selectedTab === 'gym-overview') {
      history.push(`${match.path}/gym-overview`);
    }
  }, [selectedTab, history, match.path]);

  return (
    <Layout fullWidth>
      <ResizeObserver
        onResize={(rect) => {
          if (!isSideBarOpen && rect?.width > 714) {
            dispatch(setSideBarOpen(true));
          }
          if (isSideBarOpen && rect?.width <= 714) {
            dispatch(setSideBarOpen(false));
          }
        }}
      />
      <div className='overflow-scroll-boi' />
      <Helmet>
        <title>GymStudio | TeamBuildr</title>
      </Helmet>
      <FlexContainer>
        <SideBar isSideBarActive={isSideBarActive} setIsSideBarActive={setIsSideBarActive} />
        <Switch>
          <Redirect exact from={match.path} to={`${match.path}/gym-overview`} />
          <Route path={`${match.path}/quick-statistics`}>
            <PageWrapper isSideBarActive={isSideBarActive}>
              <QuickStatisticsDashboard currentTab={selectedTab} />
            </PageWrapper>
          </Route>
          <Route path={`${match.path}/gym-overview`}>
            <PageWrapper isSideBarActive={isSideBarActive}>
              <GymOverviewDashboard currentTab={selectedTab} />
            </PageWrapper>
          </Route>
        </Switch>
        {!isSideBarActive ? (
          <SideBarReturnButton onClick={() => setIsSideBarActive(true)}>
            <ModuleText>
              RETURN TO SIDEBAR
            </ModuleText>
          </SideBarReturnButton>
        ) : null}
      </FlexContainer>
    </Layout>
  );
};

GymStudio.propTypes = { match: PropTypes.instanceOf(Object).isRequired };

export default GymStudio;
