import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Icon from '../../../../../shared/_TBComponents/Icon';

const CardContainer = styled('div')`
  position: relative;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  width: ${({ isResponsive }) => (isResponsive ? '100%' : '344px')};
  height: ${({ isResponsive }) => (isResponsive ? '228px' : '400px')};
  padding: ${({ isResponsive }) => (isResponsive ? '30px' : '20px')};
  border-radius: 12px;
  display: flex;
  flex-direction: ${({ isResponsive }) => (isResponsive ? 'row' : 'column')};
  align-items: ${({ isResponsive }) => (isResponsive ? 'flex-start' : 'center')};
  color: #ffffff;
  overflow: hidden;
  box-shadow: 0px 4px 4px #00000040;
  // Background Image
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-size: cover;
  background-repeat: no-repeat;

  // Overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ overlayColor }) => overlayColor};
    z-index: 1;
  }
`;

const InfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${({ isResponsive }) => (isResponsive ? 'flex-start' : 'center')};
  justify-content: center;
  width: ${({ isResponsive }) => (isResponsive ? '100%' : '90%')};
  margin-top: ${({ isResponsive }) => (isResponsive ? '0' : '32px')};
  margin-bottom: ${({ isResponsive }) => (isResponsive ? '0' : 'auto')};
`;

const IconWrapper = styled('div')`
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
  background-color: ${({ iconBgColor }) => iconBgColor};
  border: 4px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
`;

const Title = styled('h3')`
  font-size: 24px;
  font-weight: 900;
  color: #ffffff;
  text-align: ${({ isResponsive }) => (isResponsive ? 'left' : 'center')};
  margin: 0 0 8px;
  z-index: 2;
`;

const Description = styled('p')`
  font-size: 14px;
  text-align: ${({ isResponsive }) => (isResponsive ? 'left' : 'center')};
  margin: 0 0 16px;
  z-index: 2;
`;

const Badge = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 180px;
  gap: 8px;
  padding: 4px 12px;
  background-color: #ffffff33;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  z-index: 2;
  align-self: ${({ isResponsive }) => (isResponsive ? 'flex-end' : 'center')};
  position: ${({ isResponsive }) => (isResponsive ? 'absolute' : 'static')};
  top: ${({ isResponsive }) => (isResponsive ? '40px' : 'auto')};
  right: ${({ isResponsive }) => (isResponsive ? '40px' : 'auto')};
`;

const CircleIcon = styled('span')`
  width: 16px;
  height: 16px;
  background-color: #0DCC8A;
  border-radius: 50%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const MarketingCard = ({
  backgroundImage,
  backgroundPosition,
  overlayColor,
  icon,
  iconSize,
  iconBgColor,
  title,
  description,
  availability,
  isResponsive,
}) => (
  <CardContainer
    backgroundImage={backgroundImage}
    backgroundPosition={backgroundPosition}
    overlayColor={overlayColor}
    isResponsive={isResponsive}
  >
    <InfoWrapper isResponsive={isResponsive}>
      <IconWrapper iconBgColor={iconBgColor}>
        <Icon size={iconSize} icon={icon} />
      </IconWrapper>
      <Title isResponsive={isResponsive}>{title}</Title>
      <Description isResponsive={isResponsive}>{description}</Description>
    </InfoWrapper>
    <Badge isResponsive={isResponsive}>
      <CircleIcon />
      {availability}
    </Badge>
  </CardContainer>
);

MarketingCard.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  backgroundPosition: PropTypes.string,
  overlayColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  iconBgColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  availability: PropTypes.string,
  isResponsive: PropTypes.bool,
};

MarketingCard.defaultProps = {
  backgroundPosition: 'center',
  overlayColor: '#06283D99',
  availability: 'AVAILABLE NOW',
  iconSize: 32,
  iconBgColor: '#06283D',
  isResponsive: false,
};

export default MarketingCard;
