import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { push } from 'connected-react-router';

import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import Icon from '../../../../shared/_TBComponents/Icon';
import Text from '../../../../shared/components/Text/Text';
import PreviewImage from '../../../../shared/images/gymstudio-preview.png';

const DropdownContainer = styled('div')`
  position: relative;
  display: inline-block;
  font-family: 'Nunito Sans';

  &:hover .dropdown-content {
    display: block;
  }
`;

const DropdownButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #e66465, #9198e5);
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  position: relative;

  @media (max-width: 992px) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
  }

  @media (min-width: 993px) {
    width: auto;
    height: auto;
    border-radius: 1000px;
    padding: 8px 12px;
  }

  @media (max-width: 819px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

const Badge = styled('div')`
  background-color: #ffffff;
  color: #000000;
  font-size: 9px;
  font-weight: 700;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 10px;

  @media (max-width: 992px) {
    display: none;
  }
`;

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;

  @media (max-width: 1100px) {
    font-size: 12px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

const StyledIconWrapper = styled('div')`
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`;

const DropdownContent = styled('div')`
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 200px;
  height: 245px;
  padding: 4px;
  z-index: 10;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 10px;
  }
`;

const DropdownArrow = styled('div')`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
  background: #ffffff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
`;

const NavLink = styled('a')`
`;

const DropdownImage = styled('img')`
  width: 100%;
  height: 50%;
  object-fit: cover;
  margin-bottom: 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const DropdownBody = styled('div')`
  padding: 0 6px;
`;

const DropdownTitle = styled(HeaderText)`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.text};
`;

const DropdownDescription = styled('span')`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondaryText};
`;

const FeatureButton = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleReactLink = (path, e) => {
    e.preventDefault();
    dispatch(push(path));
  };

  return (
    <DropdownContainer>
      <NavLink
        onClick={(e) => { handleReactLink('/gymstudio/gym-overview', e); }}
        href='/gymstudio/gym-overview'
      >
        <DropdownButton>
          <Badge>NEW!</Badge>
          <StyledText>GymStudio</StyledText>
          <StyledIconWrapper>
            <Icon icon='gymstudio' color='#FFFFFF' size={18} />
          </StyledIconWrapper>
        </DropdownButton>
      </NavLink>
      <DropdownContent className='dropdown-content'>
        <DropdownArrow />
        <NavLink
          onClick={(e) => { handleReactLink('/gymstudio/gym-overview', e); }}
          href='/gymstudio/gym-overview'
        >
          <DropdownImage src={PreviewImage} alt='GymStudio Preview' />
        </NavLink>
        <DropdownBody>
          <DropdownTitle theme={theme}>TeamBuildr & GymStudio</DropdownTitle>
          <DropdownDescription>
            Sync your gym&apos;s data and get instant access to personalized statistics in seconds!
          </DropdownDescription>
        </DropdownBody>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default FeatureButton;
